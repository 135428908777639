import React from "react";
import { Helmet } from "react-helmet";

//images 
import Picture1 from "./pics/Picture1.jpg";
import Picture2 from "./pics/Picture2.jpg";
import Picture3 from "./pics/Picture3.jpg";
import Picture4 from "./pics/Picture4.jpg";



const backgroundImageUrl = "https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public"; 

export function Bridge_Blog() {


    return (
        <>
         <Helmet>
  <title>Dental Bridges Blog</title>
  <meta name="description" content="Learn about Dental Bridges and how they can benefit you." />
  <meta name="keywords" content="Dental Bridges, dental treatment, oral health" />
  <meta property="og:title" content="Dental Bridges Blog" />
  <meta property="og:description" content="Learn about Dental Bridges and how they can benefit you." />
  <meta property="og:image" content={Picture1} />
  <link rel="canonical" href="/Bridge-blog"/>
  <meta property="og:url" content="https://havelockdental.com/Bridge-blog" />
</Helmet>
        <div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public')`
    //backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Dental Bridges Blog</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>
       <div className="rtc_Blog_01">
       
       <div className="Contentwrapper">
      <div className="cardwrapper">
        <div className="card">
              <h2>What are dental bridges?</h2>
              <p>If you have one or more missing teeth, a dental bridge can fill the gap with one or more artificial teeth. A bridge is typically made of crowns on either side of the missing tooth or teeth supporting the pontic (false tooth) and is cemented in place.</p>
              <img src={Picture1}style={{ maxWidth: '250px',width:'50%' }} alt="" />
              <img src={Picture2}style={{ maxWidth: '250px',width:'50%' }} alt="" />
          </div>

          <div className="card" style={{textAlign: 'left'}}>
          <h2>Why do you need a dental bridge?</h2>

          <p>Your teeth work together. If a tooth is missing, nearby teeth can move into the empty space. The teeth in your opposite jaw can also move up or down toward the space. This can cause:</p>
          <ul>
        <li>Bite problems.</li>
        <li>Chewing difficulties.</li>
        <li>Pain from the extra stress on your teeth and jaw.</li>
        <li>Self-consciousness about the way you look or your smile.</li>
      </ul>        </div>


      <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
          <h2>The four main types of bridges are:</h2>
          <br />

          <ul>

<li> <strong>Traditional fixed bridge:</strong> This bridge is the most common. It has two or more crowns and a filler tooth or teeth that are all connected. The crowns keep the bridge in place. Traditional bridges are made of metal, porcelain fused to metal, or ceramics.</li>
<li><strong>Cantilever bridge:</strong> In this bridge type, the pontic connects to only one abutment tooth. This can sometimes be an option for people who have teeth on only one side of the gap.</li>
<li><strong>Maryland dental bridge (resin-bonded bridge):</strong> You may have this type of bridge if you have missing front teeth. It’s made of porcelain fused to metal or ceramic teeth, supported by a framework. Wings on each side of the bridge bond to your existing teeth.</li>

<li><strong>Implant supported bridge:</strong> This bridge is similar to a “traditional fixed bridge” but instead of being cemented in place to teeth, it is held in place by implants</li>


<img src={Picture3} style={{ maxWidth: '250px',width:'50%' }} alt="" />
</ul>

          </div>
          <div className="content_two">
  
<img src={Picture4} style={{ maxWidth: '250px',width:'50%' }} alt="" />
<p>Missing teeth can affect how you eat, talk and smile. A dental bridge can restore the look and function of your mouth. Dental bridges, which fill in the gap caused by missing teeth, can last for many years. Maintain good oral hygiene and see your dentist regularly for cleanings.</p>
   
          </div>
        </div>
            
      </div>
    </div>


      


      
  




    </div>
       </>

    );
}


export function Bridge_Blog_summery() {
    return (
        <div className="Blog_Summery" style={{ 
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #66be9e8f 85%), url(${backgroundImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}>
              <h2>Dental Bridges</h2>
              <p>If you have one or more missing teeth, a dental bridge can fill the gap with one or more artificial teeth. A bridge is typically made of crowns on either side of the missing tooth or teeth supporting the pontic (false tooth) and is cemented in place.</p>
   <a href="/Bridge-blog">Read More</a>

       </div>
    );
}