export default function Cosmetic_Aesthetic_Dentistry() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Cosmetic/Aesthetic Dentistry</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
        
      <div className="card">
        <h2>What are Veneers / Non Prep Veneers?</h2>
        <p>
          In the event that the pulp becomes inflamed or infected because of either deep decay, repeated dental procedures on the tooth, faulty crowns, or a crack or chip in the tooth, an endodontic treatment has to be performed. If left untreated, pulp inflammation can result in abscess. To understand this procedure, it helps to know about the anatomy of the tooth. A soft tissue known as the pulp is found under the white enamel and a hard layer that is our dentin. It contains blood vessels, nerves and connective tissue that help in the root growth of your tooth during development. The pulp becomes unnecessary once the tooth is fully developed as the tooth is nourished by the surrounding tissue.
        </p>
      </div>

      <div className="card">
        <h2>How Does Endodontic Treatment Save The Tooth?</h2>
        <p>
          Veneers are thin, custom-made shells crafted of tooth-colored materials intended to cover the front (facial) part of the teeth for improved appearance.
        </p>
      </div>

      <div className="card">
        <h2>How Does Endodontic Treatment Save The Tooth?</h2>
        <p>
          Teeth with gaps between them (to close the space between these teeth) Several visits are necessary to complete the treatment but the tooth preparation is minimal and confined to the enamel structure. The veneer is bonded to the tooth structure with tooth-colored resin cement. Several visits are necessary to complete treatment. Patients should be aware that this is usually an irreversible process because it’s necessary to remove a small amount of enamel from your teeth to accommodate the shell.
        </p>
      </div>
      <div className="cardsplitcard">
      <div className="contentone" style={{ placeSelf: 'center' }}>
        <h2>What Types Of Problems Do Dental Veneers Fix?</h2>
        <p>
          Veneers are thin, custom-made shells crafted of tooth-colored materials intended to cover the front (facial) part of the teeth for improved appearance.
        </p>
      </div>
      <div className="content_two">
        <strong>
          Veneers are routinely used to fix:<br />
          • Discolored teeth that can be caused by root canal treatment; stains from tetracycline or other drugs, excessive fluoride or other causes; like the presence of large resin fillings.<br />
          • Teeth that are worn down<br />
          • Misaligned, uneven, or irregularly shaped teeth (for example, have craters or bulges in them)<br />
        </strong>
      </div>
    </div>

    <div className="card">
        <h2>What Is Teeth Whitening?</h2>
        <p>
          Teeth whitening is the most common cosmetic dental procedure sought by people primarily to remove the accumulated stains on the teeth. These stains can be removed quickly, safely and with minimal discomfort utilizing In-Office whitening systems or custom home trays. We will be able to determine which treatment or combination of treatments is the best for you after a thorough examination.
        </p>
      </div>

      <div className="card">
        <h2>Which Is a Better Way to Whiten Your Teeth? In-Office or At-Home?</h2>
        <p>
          For people who have limited time and want instant results, In-Office whitening is the better choice. Most patients do the In-Office whitening in combination with custom home trays in order to continue the whitening process, or to help lock the shade that was achieved.At-Home whitening includes custom trays delivered to the patient along with the whitening material and proper instructions for use, this method lasts about 7-21 days, and may vary according to the result that the patient wants. This method provides a steady result for about 2 years. Patients can keep the product in the fridge and use it later on for a touch-up before a special event or to just maintain the result.
        </p>
      </div>

      <div className="card">
        <h2>How Long Does the Whitening Last Before I Have to Do It Again?</h2>
        <p>
          With good oral care, the procedure’s results may last over two years. Of course, those patients who smoke, drink dark teas and coffee are more likely to relapse sooner and require additional whitening sessions. These patients can benefit more from the At-Home system which provides steadier results.
        </p>
      </div>



      <div className="card">
        <h2>
          Are There Any Alternatives To Improving My Smile Other Than Whitening
          My Teeth?
        </h2>
        <p>
          We recommend you to consult one of our Dentist at HDC, if you have
          tooth-colored fillings, crowns or bonding in your front teeth, as it
          will not change the color of these materials, causing them to stand
          out in your newly whitened smile. Fillings:As an alternative to the
          usual silver/mercury fillings, we offer tooth-colored fillings for a
          more natural look. Porcelain inlays and composite resins create
          fillings that are not only more attractive, but also add strength to
          the weakened teeth. These restorations are also quite durable and
          long-lasting thanks to improved bonding technologies.
        </p>
      </div>
      <div className="card">
        <h2>Why Should I Consider White Fillings?</h2>
        <p>
          Aside from its functionality, many people consider aesthetic reasons
          when it comes to their dental treatment. Hence, the white filling has
          become popular since it is more natural-looking than the silver
          fillings.
        </p>
      </div>
      <div className="card">
        <h2>Are White Fillings As Good As Silver Amalgam Fillings?</h2>
        <p>
          White fillings have always been considered less long lasting than
          silver amalgam fillings. But now, there are new materials available
          with properties comparable to silver amalgam, which are proving to be
          very successful. The life expectancy of a white filling can depend
          greatly on where it is placed in your mouth and how heavily your
          teeth come together when you bite.
        </p>
      </div>
      <div className="card">
        <h2>Is It Worth Replacing My Amalgam Fillings With White (Composite) Ones?</h2>
        <p>
          It is usually best to change fillings only when the dentist advises you to. If such a situation arises, you can ask to have it replaced with the tooth-colored material. White fillings are not always appropriate for teeth with large cavities. An alternative would be to place porcelain restorations such as inlays or crowns.
        </p>
      </div>
      <div className="card">
        <h2>What are Crowns / Bridges?</h2>
        <p>
          A crown is a cap that is placed over a tooth and held in place by dental adhesive or dental cement to treat problems of decay, fractured teeth, and malocclusion, as well as to protect teeth which have undergone root canal treatment or large fillings. They can also be used to change tooth color and shape.
          A bridge (also known as a fixed partial denture) is planned when one or more teeth are missing and there are healthy teeth on both sides of the open space.
        </p>
      </div>



      <div className="cardsplitcard">
      <div className="contentone" style={{ placeSelf: 'center' }}>
      <h2>What Are Crowns Used For?
                      </h2> 
                      <strong>
      • Gives protection for badly decayed or fractured teeth.<br/>
      • As a permanent restoration for teeth with large fillings.<br/>
      • To correct minor problems in natural teeth like spacing, irregular shape or severe discolouration.<br/>
        </strong>    
      </div>
  
    </div>


    <div className="card">
                        <h2>
                          What Is the Procedure To Get A Crown/Bridge? </h2>
                        <p>A normal process for a full crown restoration needs 2-3 appointments. Although the majority of crown treatments are completed in two visits, there is sometimes a need for a third visit to ensure that it is properly fit.On the first visit, the tooth will be reshaped by filing down the tooth so that the crown can be placed over it. You will be given a local anesthetic before this part of the procedure so that no discomfort is experienced. Next, an impression/ intraoral scan will be taken of that tooth and of the surrounding teeth to be sent to the lab for the crown fitting. A temporary crown will be placed until the permanent one is ready.The second visit sees the placement of the final restoration and the permanent crown will be fitted comfortably into the mouth. Every effort will be made to ensure that the new tooth feels exactly like one of your natural teeth before going to the final step, which is to cement the crown into your mouth.  </p>
                       </div>  

                       <div className="card">
                        <h2>
                          How Does Age Affect Our Smile?    </h2>
                          <p>There is nothing more attractive on a face than having a beautiful smile, and our teeth play a huge role in that. Aside from white, perfect teeth being associated with youth and vitality, it also gives our face frame and support.But as time passes, our teeth go through some wear and tear, and naturally turns yellow. Aging and tooth deterioration can lead to a shortened vertical face dimension, as the distance between the tip of our nose and the bottom of our chin gets smaller, resulting in wrinkles and compressed lips that can make you look older than you really are.The loss of teeth also results in bone absorption, making your cheeks flat and inclined; giving you a bony look. The muscles are also weakened, and the temporo-orbital arches disappear. In the case of tooth bruxism during sleep, the wearing out of the teeth is much faster!</p>
                       </div>  
                       <div className="card">
                        <h2>
                          What Is A Dental Face Lift Work?   </h2>
                          <p>A dental face lift is an anti-aging method that focuses on your teeth to give you a much more youthful appearance.The process involves teeth restoration and a smile reconstruction, which involves improving the facial shape, profile and jaw line.Thin lips can appear fuller without artificial fillers, as they will be supported by your teeth. The face is lengthened, which automatically smoothes out the wrinkles around your lips and the corners of your mouth.All old fillings, crowns and decays are removed, your teeth are cleaned, and missing teeth are replaced with dental implants. Porcelain veneers and porcelain crowns are also placed on teeth, offering impressive and lasting results.A dental face lift will restore your cheerful smile, while assuring that your new teeth look completely natural; overall forming a much more youthful and attractive appearance, and offering all-important self-confidence.</p>
                       </div>  

      </div>
    </div>
    
    
    </>
    );
  }