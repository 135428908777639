import React, { useState, useRef } from 'react';

const BeforeandAfter = () => {
  const [sliderValue1, setSliderValue1] = useState(50);
  const [sliderValue2, setSliderValue2] = useState(50);

  const divisor1Ref = useRef(null);
  const divisor2Ref = useRef(null);

  function handleSlider1Change(event) {
    setSliderValue1(event.target.value);
  }

  function handleSlider2Change(event) {
    setSliderValue2(event.target.value);
  }

  return (
    <div className="beforeafter">
      <div className="comparison">
        <figure style={{ backgroundImage: 'url(https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/6827b2c1-3e5e-49d0-22d1-537056787d00/public)' }}>
          <div
            style={{ width: `${sliderValue1}%`, backgroundImage: 'url(https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/2dafde9e-d13d-48c0-f1fe-a6e319c4aa00/public)' }}
            className="divisor"
            ref={divisor1Ref}
          />
        </figure>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue1}
          className="slider"
          onInput={handleSlider1Change}
        />
      </div>
      <div className="comparison">
        <figure style={{ backgroundImage: 'url(https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/14f23142-efb5-4791-f55a-262e0dd92100/public)' }}>
          <div
            style={{ width: `${sliderValue2}%`, backgroundImage: 'url(https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/b2cc8db7-859f-4440-2764-c1e13651c200/public)' }}
            className="divisor"
            ref={divisor2Ref}
          />
        </figure>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue2}
          className="slider"
          onInput={handleSlider2Change}
        />
      </div>
    </div>
  );
};

export default BeforeandAfter;
