export default function Pedodontic() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Pedodontic</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
        

      <div className="card">
      <h2>What Is Pediatric Dentistry/ Pedodontics?</h2>
            <p>Pediatric dentistry is one of the seven recognized clinical specialties of dentistry. It includes all areas of adult dentistry that may be applied to children. However, it also includes many procedures that are specific to the peculiar needs of primary (baby) teeth and challenges of the developing child. The most significant difference between pediatric and adult dentistry is the need for dentists treating children to understand and practice sound psychological principles to allow children to have positive, relatively pain-free experiences in this important health area. Bad memories of traumatic dental therapy during childhood can influence children negatively and make them reject adequate dental care for life. We at Havelock Dental Center promise to be an excellent pediatric or general dentist for your child. </p>
             </div>
      <div className="card">
      <h2>Who is a Pediatric Dentist?</h2>
            <p>Veneers are thin, custom-made shells crafted of tooth-colored materials intended to cover the front (facial) part of the teeth for improved appearance.
            </p>
        </div>



           <div className="cardsplitcard">
            <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
            <h2>Who is a Pediatric Dentist?
              </h2> 
              <p>
                Pediatric dentists are dedicated to the oral health of children from infancy through the teen years. They have the experience and qualifications to care for a child’s teeth, gums, and mouth throughout the various stages of childhood.
                Children begin to get their baby teeth during the first 6 months of life. By age 6 or 7 years, they start to lose their first set of teeth, which eventually are replaced by secondary, permanent teeth. Without proper dental care, children face possible oral decay and disease that can cause a lifetime of pain and complications. Today, early childhood dental caries—an infectious disease—is 5 times more common in children than asthma and 7 times more common than hay fever.        </p>    
           </div>
            <div className="content_two"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
            <h2>Pediatric Dentists — The Best Care For Children
                </h2> 
                 <p> 
                  Children are not just small adults. They are not always able to be patient and cooperative during a dental exam. Pediatric dentists know how to examine and treat children in ways that make them comfortable. In addition, pediatric dentists use specially designed equipment in offices that are arranged and decorated with children in mind.
                  A pediatric dentist offers a wide range of treatment options, as well as expertise and training to care for your child’s teeth, gums, and mouth. When your pediatrician suggests that your child receive a dental exam, you can be assured that a pediatric dentist will provide the best possible care.</p>
            </div>
             
            </div>
    
        
            <div className="cardsplitcard">
            <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
            <h2>Who is a Pediatric Dentist?
                  </h2> 
                  <strong>
                    • Infant oral health exams, which include risk assessment for caries in mother and child<br/>
                    • Preventive dental care including cleaning and fluoride treatments, as well as nutrition and diet recommendations<br/>
                    • Habit counseling (for example, pacifier use and thumb sucking)<br/>
                    • Early assessment and treatment for straightening teeth and correcting an improper bite (orthodontics)<br/>
                    • Repair of tooth cavities or defects.<br/>
                    • Diagnosis of oral conditions associated with diseases such as diabetes, congenital heart defect, asthma, hay fever, and attention deficit/ hyperactivity disorder<br/>
                    • Management of gum diseases and conditions including ulcers, short frenulae, mucoceles, and pediatric periodontal disease<br/>
                    • Care for dental injuries (for example, fractured, displaced, or knocked-out teeth).<br/>
                  </strong>
                          </div>
                     
            </div>




      </div>
    </div>
    
    
    </>
    );
  }