
import { Helmet } from "react-helmet";

export default function Dr_Tharangini_R_Asvinash() {
    return (
      
<>
<Helmet>

  <title>Dr. Tharangini R. Asvinash - Director at Havelock Dental</title>
  <meta name="description" content="Learn about Dr. Tharangini R. Asvinash's qualifications, experience, and passion for dentistry. Contact her for quality dental care at Havelock Dental." />
  <meta name="keywords" content="Dr. Tharangini R. Asvinash, Havelock Dental, dental care, cosmetic dentistry, orthodontics, implantology, digital dentistry, general dentistry" />
  <meta property="og:title" content="Dr. Tharangini R. Asvinash - Director at Havelock Dental" />
  <meta property="og:description" content="Learn about Dr. Tharangini R. Asvinash's qualifications, experience, and passion for dentistry. Contact her for quality dental care at Havelock Dental." />
  <meta property="og:image" content="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/5c253821-56f0-4448-0728-cd7872916600/public" />
  <link rel="canonical" href="/TharanginiAsvinash"/>
  <meta property="og:url" content="https://havelockdental.com/TharanginiAsvinash" />
</Helmet>
<div className="doctorPagewrapper">
    <div className="doctor">
        <div className="card">
            <div className="docimg">
            <a href="#">
                <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/5c253821-56f0-4448-0728-cd7872916600/public" alt=""/>
            </a>
            </div>
            <div className="doccontent">
            <h2>Dr. Tharangini.</h2><h2 className="outline"> R. Asvinash</h2>
                        <h2>Director</h2>
        
                        <h1>BDS, FICCDE (Orthodontics)</h1>
                        <a href="tel:+94777347570">+94 (77) 734 7570</a><br/>
                        <a href="mailto:tharangini@havelockdental.com">tharangini@havelockdental.com</a>
            </div>
            <div className="Doc_Extras">
            <p>
            Dr. Tharangini R. Asvinash is a highly skilled and experienced dentist with 8 years of clinical experience. She obtained her BDS (Bachelor of Dental Surgery) degree from Saveetha Dental College and Hospitals in India. She also has additional training in orthodontics, having completed a fellowship program in FICCDE (Fellowship in International College of Continuing Dental Education) in Orthodontics.
            </p>
        <br />
        <br />
        <p>
        Dr. Asvinash is committed to providing her patients with high-quality dental care using the latest techniques and technologies. To stay up to date with the latest advancements in the field, she has completed continuing education courses in several areas, including implantology, digital dentistry, clinical occlusion, orthodontics, and aesthetic dentistry.
        </p> <br />
        <br />
        <p>
        As a member of the Sri-Lanka Dental Association, Dr. Asvinash is dedicated to promoting the importance of oral health and preventative dental care. She believes in building strong relationships with her patients, and strives to make every dental appointment a positive and comfortable experience.
        </p>
        <br />
        <br />
        <p>
        In addition to her expertise in orthodontics, Dr. Asvinash also has experience in cosmetic dentistry, including teeth whitening, porcelain veneers, and composite bonding. She is passionate about helping her patients achieve the beautiful, healthy smiles they deserve.
        </p>
        <br />
        <br />
        <p>
        Overall, Dr. Tharangini R. Asvinash is a highly skilled and compassionate dentist who is dedicated to providing the best possible care to her patients.
        </p>
            </div>
        </div>
    </div>
   </div>

    
    </>
    );
  }