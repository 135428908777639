import React from "react";
import CallBackrequest from "../Components/CallBackrequest";
import LiveCount from "../Components/LiveCount";
import BeforeandAfter from "../Components/BeforeandAfter";
import AboutUs from "../Components/AboutUs";
import Directors from "../Components/Directors";
import TreatmentQuicklinks from "../Components/TreatmentQuicklinks";
import { Helmet } from "react-helmet";
import {FaqList} from "../Components/HomeFaq";
export default function Home() {
    console.log("Home");
    return  (

        <>
        <div className="background">
        <div  style={{ backgroundColor: 'lightblue' }}>
  <iframe 
    src="https://customer-q5qhw1likkzp50mf.cloudflarestream.com/b3d2ad56ba7756278986947a61f48d54/iframe?muted=true&loop=true&autoplay=true&poster=https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/03fed140-72dc-4f14-e63c-d20712c1f100/public%3Ftime%3D%26height%3D600&controls=false" 
    style={{ border: 'none', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }} 
    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" 
    allowFullScreen={true} 
  />
</div>
      </div>
      <div className="content_home">
      <Helmet>
<title>Havelock Dental Center</title>
<meta name="description" content="Havelock Dental Centre provides the best dental treatment in Sri Lanka, that uses world-className dental techniques in state-of-the-art facilities. We offer the most comprehensive oral health solutions on par with international standards, all under one roof. we Have been in operation since 1970, serving thousands of happy patients over 52 years." />
<meta name="keywords" content="Best Dental Clinic in Sri lanka ,Havelock Dental Center, dental treatment, Sri Lanka, world-className dental techniques, state-of-the-art facilities, comprehensive oral health solutions, international standards, 52 years of service" />
<meta property="og:title" content="Havelock Dental Center" />
<meta property="og:description" content="Havelock Dental Centre provides the best dental treatment in Sri Lanka, that uses world-className dental techniques in state-of-the-art facilities. We offer the most comprehensive oral health solutions on par with international standards, all under one roof. we Have been in operation since 1970, serving thousands of happy patients over 52 years." />
<meta property="og:image" content="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/03fed140-72dc-4f14-e63c-d20712c1f100/public?time=&height=600" />
<meta property="og:url" content="https://havelockdental.com/" />  
<link rel="canonical" href="/"/>

</Helmet>
    <CallBackrequest/>
    <LiveCount/>
    <Directors/>
    <BeforeandAfter/>
    <AboutUs/>
   
    <TreatmentQuicklinks/>

    <FaqList/>
      </div>
      </>

    )
}