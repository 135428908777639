export default function Prosthodontics() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Prosthodontics</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">


        

        
      <div className="card">
            <h2>What does prosthodontics provide?

            </h2>
            <p>This branch and its specialized doctors provide extra care for your tooth especially if it is missing and needs a replacement like a bridge, or if its function is impaired due to tooth decay for example and requires a crown or even a tooth implant. It also ensures that when fixing the dental problem, 
              it restores its function too and most importantly maintains a natural and aesthetically attractive appearance.</p>
           </div>  
           <div className="cardsplitcard">
         <div className="contentone" style={{ placeSelf: 'center' }}>
            <h2>What are the common problems that prosthodontics treat?
            </h2>
            <p>Prosthodontics is concerned with the management and treatment of many oral issues most revolving around missing teeth and function due to tooth decay or gingivitis or other oral problems, but it also helps with cleft palate, snoring and sleep apnea.
                This is also concerned with working in a team that treats dental injury due to trauma or accidents.</p>
               
         </div>
            <div className="content_two">
              <strong>  A prosthodontist helps treat:<br/>
                • Aesthetic dentistry<br/>
                • Dental implants<br/>
                • Fixed and removable dentures<br/>
                • Replacing missing teeth<br/>
                • Geriatric patients with special oral needs<br/>
                • Snoring and sleep apnea disorders.<br/>
                • Congenital missing teeth or cleft palates.<br/>
                • Treatment of temporomandibular joint syndrome/disorder (TMD)<br/>
                • Traumatic injuries that affect the teeth and/ or jaw.<br/>
                • Oral cancer treatment and reconstruction.<br/>
            </strong>
                </div>
            </div>

            <div className="card">
                <h2>How does prosthodontics work?
                </h2>
                <p>The main focus is to restore the function and appearance of this tooth. The problem could be solved by reshaping your tooth, installing bridges, crowns, dentures, implants or veneers.

                </p>
               </div>

               <div className="card">
                <h2>What are Dentures?
    
                </h2>
                <p>If you miss more than a few teeth, then dentures are in order.
                    Dentures must be designed to look and feel real and comfortable. It may offer a perfect solution for many patients but many others don’t feel comfortable with it.
                    
                </p>
               </div>

               <div className="card">
                <h2>What are Night guards?
    
                </h2>
                <p>This solution is magical for people with a temporomandibular joint disorder, it helps relieve the pain as it lessens the pressure exerted on your joint.
   
                </p>
               </div>

               <div className="card">
                <h2>What are Porcelain Veneers?
                </h2>
                <p>They are wafer-thin shells made of a ceramic material that bond to the front surfaces of the teeth that requires a dramatic appearance change or do-over.
                    After removing a part of the enamel, a certain bond is applied on this tooth then this veneer that is custom-designed to fit this tooth’s size. shape and color will be installed. And it can also be used to fill gaps between teeth.
                </p>
               </div>








      </div>
    </div>
    
    
    </>
    );
  }