import React from "react";
import { Helmet } from "react-helmet";





const backgroundImageUrl = "https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public"; 

export function Teeth_Whitening_Blog() {


    return (
        
<>
<Helmet>
  <title>Teeth Whitening Blog</title>
  <meta name="description" content="Learn about Teeth Whitening treatments and how they can benefit you." />
  <meta name="keywords" content="Teeth Whitening, dental treatment, oral health" />
  <meta property="og:title" content="Teeth Whitening Blog" />
  <meta property="og:description" content="Learn about Teeth Whitening treatments and how they can benefit you." />
  <meta property="og:image" content="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public" />
  <link rel="canonical" href="/Teeth-Whitening-blog"/>
  <meta property="og:url" content="https://havelockdental.com/Teeth-Whitening-blog" />
</Helmet>
<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Teeth Whitening Blog</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>
<div className="rtc_Blog_01">

<div className="Contentwrapper">
      <div className="cardwrapper">
        <div className="card">
              <h2>WHAT IS TEETH WHITENING?</h2>
  <br />
              <p>
Teeth whitening are an option to brighten your smile. You may have tried store bought whitening toothpaste or teeth whitening kits. Perhaps you even had some degree of success with less expensive options. But professional teeth whitening systems are by far the most effective method to achieve your whitest teeth yet.
</p>    </div>
<div className="card" style={{textAlign: 'left'}}>
<h2>There are the options to whiten your teeth:</h2>
  <br />
<ul>
  <li>At-home custom tray system
</li>
<li>In-Office Bleaching
</li>
<li>
At-home and in-office system combination

</li>

<p>With your dentist, you can decide which course would best fit your schedule and desired results. As soon as you’re ready for professional treatments, you’ll experience a variety of benefits.</p>

<p>To Check if you can undergo the teeth whitening process and if you are an ideal candidate for teeth whitening and which method is the best for you.</p>

</ul>

  </div>

  <div className="card" style={{textAlign: 'left'}}>
  <h2>What Are the Causes Of Teeth Yellowing or staining?</h2>
  <br />

  <p>Having yellow teeth is a common condition that many people find undesirable. Here are a few things that can potentially stain your teeth.</p>


  </div>


  <div className="card" style={{textAlign: 'left'}}>
  <h2>What Are the Causes Of Teeth Yellowing or staining?</h2>
  <br />

  <p>Having yellow teeth is a common condition that many people find undesirable. Here are a few things that can potentially stain your teeth.</p>
  <br />
  <ul>
  <li>Smoking: Excessive use of nicotine can cause an unhealthy addiction and yellow/brown surface stains.</li>
  <li>Old Age: As you get older, your tooth enamel will begin to wear down from exposure to acidic foods and drinks over time. Age-related yellowing is unavoidable.</li>
  <li>Medications: Specific medications may have side effects that cause teeth yellowing. Look on the back of the label or ask your doctor if teeth yellowing is a symptom of your current medication.</li>
  <li>Teeth Grinding: Although accidental or an unknown habit, it’s common for people to grind their teeth in their sleep. Excessively grinding your teeth can cause tooth enamel to break down, making it a perfect candidate for yellowing.</li>
  <li>Diet: There are quite a number of foods that can cause surface stains on your teeth. A few examples of these are
  <br />
  <br />

    <ul>
      <li>Black coffee</li>
      <li>Wine</li>
      <li>Dark sodas</li>
      <li>Curry spices</li>
    </ul>
  </li>
</ul>

  </div>






  <div className="card" style={{textAlign: 'left'}}>
  <h2>Why Should You Consider Professional Whitening?</h2>
  <br />


  <p>You can brighten your smile by getting self-prescribed whitening products over-the-counter. The results of such treatments may exhibit after a while, but professionally whitened teeth are better in many ways including the following:</p>
  <br />

<ul><li>
  
 You can brighten your smile by getting self-prescribed whitening products over-the-counter. The results of such treatments may exhibit after a while, but professionally whitened teeth are better in many ways including the following:
  
  </li>
  <br />
  <li>	Fast process – within a few minutes in the dentist’s chair, your whitening treatment should be complete. The process of whitening your teeth is fast, thanks to the use of lasers and ultraviolet light to activate and speed up the bleaching process.</li>
  <br />

  <li>Effectiveness – teeth whitening in a dental clinic is very effective. In fact, patients notice the brightness and whiteness of their teeth immediately after the treatment is complete. With other methods, you may have to keep repeating the procedure over a couple of days to see any improvements in the color of your teeth.</li>
  <br />

<li>Longer lasting results – while the results are not permanent, with professional whitening, the brightness of your teeth can last up to 1 year or longer. When you brighten your teeth at home without the intervention of a dental expert, you may need a repeat procedure after a few weeks, usually up to 3 months.</li>
<br />
 <li>Boosting oral hygiene – if you are whitening your teeth by yourself, you will never get to understand the value of a deep cleaning session with a dentist. By professionally cleaning your teeth, the brightness thereof is already noticeable. The deep cleaning also boosts your oral hygiene, improving the overall welfare of your oral cavity.</li>
  </ul>

  </div>








  <div className="card" style={{textAlign: 'left'}}>
  <h2>Any Side Effects Of The Treatment?</h2>



  <p>While professional whitening has a high patient satisfaction rate and is one of the best methods for whitening teeth without damaging enamel, it isn't the right option for everyone. You may not be a good candidate if:</p>

  <ul>
  <li>You are pregnant or nursing</li>
  <li>You have sensitive gums or teeth</li>
  <li>You are allergic to peroxide</li>
  <li>You have worn enamel, cavities, exposed roots or gum disease</li>
  <li>You have crowns, fillings, or other types of dental restorations — these restorations comprise from porcelain or resin and will not whiten, so they will not match your other whitened teeth</li>
  <li>You have white spot decalcifications — these spots will become whiter and more evident after a whitening treatment</li>
  <li>You have gray-appearing stains — they will not respond as well to bleaching agents as yellowish or even brownish stained teeth.</li>
</ul>

  </div>





  <div className="card">
  <p>For more details and clarifications please call <strong>HAVELOCK DENTAL CENTER</strong> and Get a consultation from our doctors.</p>


  </div>


      </div>
    </div>





















</div>
</>

    );
}


export function Teeth_Whitening_Blog_summery() {
    return (
        <div className="Blog_Summery" style={{ 
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #66be9e8f 85%), url(${backgroundImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}>
              <h2>TEETH WHITENING</h2>
                <p>
                Teeth whitening are an option to brighten your smile. You may have tried store bought whitening toothpaste or teeth whitening kits. Perhaps you even had some degree of success with less expensive options. But professional teeth whitening systems are by far the most effective method to achieve your whitest teeth yet.
                
                </p>  <a href="/Teeth-Whitening-blog">Read More</a>

       </div>
    );
}