export default function Preventive_Dentistry() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Preventive Dentistry</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
        <div className="card">
        <h2>What Is Preventive Dentistry?</h2>
            <p>As the old saying goes, prevention is better than a cure. Hence, the creation of preventive dentistry which is a new method intended to reduce the need for expensive and painful dental treatments in the future. With the effort of the dentist and the patient, this treatment can save your teeth from decay and gum disease, which gives you a better chance of keeping your teeth for life without the need for fillings and extractions.
               </p>
                 </div>

        <div className="card">
        <h2>Is Prevention Possible?</h2>
            <p>Yes. With modern dentistry and constant new developments, it is possible to prevent, or considerably reduce dental disease. A course of treatment and maintenance may be recommended to get your mouth in good condition, and keep it that way.
            </p>
              </div>
              <div className="card">
              <h2>What Is Involved?</h2>
                <p>A thorough assessment will be done for your teeth and gums to know the necessary treatments needed. The main aim is to make your mouth completely healthy to try and prevent any dental problems from happening or returning.
                  We will thoroughly ‘scale and polish’ your teeth, then show you the best way to brush and floss to remove the bacterial ‘plaque’ on your teeth and gums. Plaque, which is an invisible film of bacteria on the teeth and gums that is formed when the sugars from the food that you eat turns into acid, eventually causes tooth decay. It can also cause gum inflammation if not regularly and thoroughly removed. The hard tartar (calculus) which builds up on the teeth also starts off as plaque. Diet and other habits such as smoking and drinking might be discussed as well for the dentist to be able to give you the best oral care products and practice to use.
                  Hygiene / Tooth Cleaning
                  Our team at Havelock Dental Surgery is uniquely qualified to assist in the prevention and treatment of oral diseases.
                  We treat gum disease and help people maintain good oral and general health by educating them on how to prevent oral diseases through taking care of their teeth and gums. People who have regular preventive dental treatment are known to be considerably healthier. We provide a dental hygiene diagnosis, which is an integral component of the comprehensive dental diagnosis, as well as performing routine prophylaxis (scaling and polishing).
                   </p>
              </div>

              <div className="cardsplitcard">
    <div style={{ display: 'grid', justifyContent: 'center' }}>
      <h2>What do WE PERFORM?</h2>
      <strong>
        • Periodontal root debridement/deep cleaning under local anesthesia<br />
        • Pit and fissure sealants (to prevent or arrest the development of dental caries)<br />
        • Teeth bleaching (whitening)<br />
        • Nutrition counselling<br />
        • Customized oral hygiene instructions (brushing, flossing techniques, etc.)<br />
        • Fluoride application<br />
        • Digital X-rays<br />
      </strong>
    </div>
  </div>
      </div>
    </div>
    
    
    </>
    );
  }