import React from "react";

export function Test_Blog() {


    return (
        <h1>Test_Blog Blog</h1>
    );
}

export function Test_Blog_summery() {


    return (
       <div className="Blog_Summery">
              <h1>Test_Blog Treatment</h1>
                <p>
                Test_Blog treatment is a procedure to repair and save a tooth that is badly decayed or infected. During root canal treatment, the nerve and pulp are removed and the inside of the tooth is cleaned and sealed. A crown is usually placed on the tooth after root canal treatment to protect it and restore its normal shape and size.
                </p>
                <a href="/RTC">Read More</a>
                
       </div>
    );
}