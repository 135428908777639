

import { useState } from "react";

export  function FaqItem({ question, answer,link }) {
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive((prevActive) => !prevActive);
  };

  return (
    <div className="faqcontainer">
      <div className={`question ${active ? "active" : ""}`} onClick={toggleActive}>
        {question}
      </div>
      <div className="answercont" style={{ maxHeight: active ? "1000px" : "0" }}>
        <div className="answer">{answer}</div>
        {
  link !== "/#" && (
    <a href={link} className="link">
      Read More
    </a>
  )
}

      </div>
    </div>
  );
}

export  function FaqList() {
  return (
    <div className="HomeFAQ">
    <div className="wrapper">
      <FaqItem
        question="What is clear aligners/ invisalign?"
        answer="Clear Aligners Clear aligners are one of the most popular treatment options for young adults because they offer a less-visible alternative to traditional orthodontic treatments (like braces). Clear aligners are made of a unique plastic material that’s customized to fit your teeth and look very similar to clear retainers. The most popular brands of in-office clear aligners are Invisalign. Invisalign still leads the industry as the “original clear aligner system.”
        "
        link={"/#"}
      />


      <FaqItem
        question="What are the orthodontic treatment available at havelock dental center?"
        answer="Brush and floss twice a day, avoid sugary and acidic foods, and visit your dentist regularly for checkups and cleanings. For more information, go to our oral hygiene page."
        link={"/#"}
      />


      <FaqItem
        question="Are dental implants painful?"
        answer="Dental implants are considered to be the best options to replace missing or damaged teeth. The procedure itself is not painful since it is performed with either general or local anesthesia to completely numb the mouth. After dental implantation, once the numbness wears off, mild pain may be noticed by the patient.
        "
        link={"/#"}
      />
      <FaqItem
        question="How does 3D Imaging and Treatment Planning help to place implants?"
        answer="State-of-the-art, highly precise 3D digital imaging and implant surgical planning software have made implant procedures faster and highly predictable. We, Havelock dental Center use these tools to analyze the anatomy of your jaw and determine the best sites for implant placement before surgery. This saves time and money, and shortens recovery time."
        link={"/#"}
      />

    </div>
    </div>
  );
}
