import React from "react";
import { Helmet } from "react-helmet";


//images 
import Picture1 from "./pics/Picture1.jpg";
import Picture2 from "./pics/Picture2.jpg";
import Picture3 from "./pics/Picture3.jpg";

import Picture4 from "./pics/Picture4.jpg";
import Picture5 from "./pics/Picture5.jpg";
import Picture6 from "./pics/Picture6.jpg";

import Picture7 from "./pics/Picture7.jpg";
import Picture8 from "./pics/Picture8.jpg";
import Picture9 from "./pics/Picture9.jpg";
import Picture10 from "./pics/Picture10.jpg";

import Picture11 from "./pics/Picture11.jpg";

import Picture12 from "./pics/Picture12.jpg";



const backgroundImageUrl = "https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public"; 

export function RTC_Blog() {


    return (
        <>
                <Helmet>
  <title>Root Canal Blog</title>
  <meta name="description" content="Learn about root canal treatments and how they can benefit you." />
  <meta name="keywords" content="root canal, dental treatment, oral health" />
  <meta property="og:title" content="Root Canal Blog" />
  <meta property="og:description" content="Learn about root canal treatments and how they can benefit you." />
  <meta property="og:image" content={Picture1} />
  <link rel="canonical" href="/root-canal-blog"/>
  <meta property="og:url" content="https://havelockdental.com/root-canal-blog" />
</Helmet>
        <div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Root Canal Blog</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>
       <div className="rtc_Blog_01">



<div className="Contentwrapper">
      <div className="cardwrapper">
        <div className="card">
        <h2>What do you need to know about Root Canal Treatments?</h2>
        <img src={Picture1} style={{ maxWidth: '250px',width:'25%' }}  alt="" />

        <p>
                If you were recently informed that you need a root canal treatment, chances are the minute you left your dentist's office, you immediately started searching the internet for information on this well-known but highly misunderstood dental service. While the internet is a great platform to learn new things and has lot of information it can be misleading as well. So let’s make it easier for you.    </p>
            

      
        </div>

        <div className="card">
        <h2>What is a Root Canal treatment?</h2>
        
<p>To best understand what a root canal treatment is, you have to know the anatomy of the tooth. The outer layer called the enamel encases a hard layer called the dentin, inside which is a soft tissue called the pulp that extends from the crown to the root of the tooth. That part of pulp that extends into the root are called the pulp canals. Your tooth pulp contains blood vessels, nerves, and connective tissue, and works to create the surrounding hard tissue of the tooth during its development but a tooth can survive without the pulp when fully matured. 
                   During the root canal treatment, your dentist will remove the inflamed or infected pulp and will clean the inside of the root canal, and then will use a sealant to close the space. The next step is placing a crown on the tooth</p>

<img src={Picture2} style={{ maxWidth: '250px',width:'25%' }}  alt="" />
<img src={Picture3} alt="" style={{ maxWidth: '250px',width:'25%' }}  />

                 
        </div>

        <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
         
<h2>What are the common symptoms that indicate a Root Canal treatment?</h2>
          <br />
<h3>Root Canal Treatment Symptoms:</h3>

<ul>
  <li>Sensitivity - to cold or heat</li>
  <li>Tenderness to touch</li>
  <li>Loosening of the tooth</li>
  <li>Oozing of pus surrounding the affected tooth</li>
  <li>Facial swelling Gum boil - What we call an abscess is usually as a result of the infection from the pulp canals reaching the jaw bone.</li>
  <li>Discolored tooth.</li>



<img src={Picture5}style={{ maxWidth: '250px' }} alt="" />
<img src={Picture6}style={{ maxWidth: '250px' }} alt="" />

</ul>
          </div>
          <div className="content_two">
          <ul>
  <li>Spontaneous Pain - The most common symptom. Pain can be while chewing, can be a radiating pain or pain that worsens at night (increased pain on lying down is due to increased blood supply to that tooth and in return increase in pressure.)</li>
<li>Painless- However, you may not experience any of the above symptoms, but may still be in need of root canal therapy to prevent further damage and to save a tooth.</li>
 
 </ul>
          <img src={Picture4}style={{ maxWidth: '250px' }} alt="" />

          </div>
        </div>



        <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
         
          <h2>Why would a tooth need a Root Canal Treatment?</h2>
          <br />


<ul>
    <li>	Deep caries being the most common reason for the need of a root canal treatment.</li>
    <li>	Trauma to the tooth due to a fall or any injury</li>
    <li>	Worn out tooth commonly due to age or grinding habits</li>
    <li>	Large fillings or repeatedly repairing a particular tooth can also lead to the need of a root canal treatment.</li>
</ul>
          </div>
          <div className="content_two">
<img src={Picture7} style={{ maxWidth: '250px',width:'25%' }}  alt="" />
<img src={Picture8} style={{ maxWidth: '250px',width:'25%' }}  alt="" />
<img src={Picture9} style={{ maxWidth: '250px' ,width:'25%'}}  alt="" />
<img src={Picture10} style={{ maxWidth: '250px' ,width:'25%'}}  alt="" />

          </div>
        </div>
      </div>
    </div>

    
       

    <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
         
          <h2>What to expect during a Root Canal Treatment?</h2>
          <p>Many patients have questions and concerns about the procedure. Thanks to modern dentistry and technology, there isn’t much to worry about! A root canal treatment is virtually pain-free and widely successful. Here are the stages your tooth will go through while under a local anesthetic</p>

          <img style={{ maxWidth: '250px',width:'25%' }} src={Picture11} alt="" />


   </div>
          <div className="content_two">
<strong>Stage 1: Diagnosing the Infected Pulp.</strong>
   
      <p>Based on your symptoms and clinical examination your dentist will use digital  x-rays to confirm that root canal treatment is required for the tooth.</p>
          <br />
  <strong>Stage 2: Getting rid of the Infected Pulp.</strong>
      <p>To get rid of the infected pulp inside the tooth, the dentist will drill a small opening on the top of the tooth. With surgical tools the dentist cleans out the root canals, removing any infection and possibly applying medication to ensure no infection is left.</p>

          <br />
 <strong>Stage 3: A new Root Canal Filling is Placed.</strong>
      <p>Your dentist will fill the cleaned canals with a new root filler. An adhesive is used to seal off the area, protecting the new root filler from any bacteria or saliva. A temporary filling is placed over the opening at the top of the tooth.</p>
        <br />
<strong>Stage 4: The Tooth is Restored.</strong>
      <p>Next your dentist will remove the temporary filling and restore it with strong permanent tooth coloured filling or place a crown over the treated tooth, making the tooth as strong as it was before the infection.</p>
          </div>
        </div>

   
        <br />
        <br />


        <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
         
          <h2>Post Root Canal treatment care</h2>


   </div>
          <div className="content_two">
        <ul>
  <li>In between your Root canal appointments you will be given a temporary filling, you have to avoid chewing on that side until your dentist completes the Root Canal treatment and gives you a permanent filling or a crown on that tooth.</li>
  <li>Maintain good Oral hygiene with brushing twice a day and flossing.</li>
  <li>Get routine dental check-ups.</li>
</ul>

          </div>
        </div>
        <br />
        <br />
        <div className="card">
        <h2>Common myth about Root Canal treatment</h2>

     <div  style={{textAlign: 'left'}}>
      
<br />
<div>
  <h3>Myth 1: Root canal therapy is painful:</h3>
  <p>There is a stigma, that root canal is a painful procedure. But, the truth is root canal therapy is performed to relieve pain. Root canals are needed when an untreated cavity spreads to the pulp of your tooth and causes an infection. This is where the pain comes from; not the procedure itself. Your dentist will numb the area around the infected tooth where you will hardly feel anything during the procedure.</p>
</div>
<br />
<div>
  <h3>Myth 2: Completing a root canal requires several appointments:</h3>
  <p>Root canal therapy may be completed in one to two appointments. Factors that determine the number of appointments necessary to complete a root canal depends on the extent of the infection and the difficulty of the root canal. Root Canals can be single or many in number on the same tooth, which in turn means more work hence, more time. Also some canals can be calcified which makes it difficult to clean again.</p>
</div>
<br />
<div>
  <h3>Myth 3: A root canal is a costly treatment:</h3>
  <p>Having a root canal and a dental crown remains less expensive than extracting a tooth and then replacing it with a bridge or a dental implant. Costs vary on how many canals a tooth has, whether it's the first time the root canal is achieved or retreatment.</p>
</div>
<br />
<div>
  <h3>Myth 4: The benefits of root canal therapy are temporary:</h3>
  <p>Results from a root canal are actually long-lasting. They relieve patients' toothaches and preserve the affected tooth indefinitely. The final restoration or crown is the key to successful results.</p>
</div>
<br />
<div>
  <h3>Myth 5: A good alternative to root canal is tooth extraction:</h3>
  <p>Sometimes, to avoid spending extra time and money for a root canal procedure, patients opt for a tooth extraction. Unfortunately, the patient may be unaware that a tooth extraction will eventually create a need for more extensive procedures and costly tooth replacements. After an extraction, the missing tooth should be replaced with restorative dental services such as dental implants or bridges, thus requiring more time in the dental chair and treatments that are much more expensive than a simple root canal.</p>
</div>

     </div>

                 
        </div>
        <br />
        <br />
        <div className="card">

     <div  style={{textAlign: 'left'}}>
<h2>What happens if a tooth is left untreated?</h2>
      
     <p>Unlike other infections, an infected pulp of the tooth will not heal on its own, it will only get worse, so a root canal is the recommended treatment. Sometimes, the pain might disappear and patients may think the infection has healed itself, however, this is just a sign that the nerves inside of the tooth have died. While there may no longer be pain present, the infection will still be inside the tooth. The only option apart from a root canal is extraction of the tooth. If you do not undergo treatment or get the tooth removed, then the consequences can be severe. If left untreated, the bacterial infection can spread to the jaw, brain, blood and rest of the body. </p>

     </div>

                 
        </div>

        <br />
        <br />
        <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
         
          <h1>So in a nutshell if you want to avoid a Root Canal Treatment (RCT) remember:</h1>
          <ul>
  <li><strong>R</strong>outine dental check up can detect caries at an early stage which might just require a simple filling</li>
  <li><strong>C</strong>are for your teeth. Brush twice a day and prevent cavities</li>
  <li><strong>T</strong>reat it early- once your dentist informs you about your cavity don’t delay it.</li>
</ul>

   </div>
          <div className="content_two">
          <img src={Picture12} alt="" />


          </div>
        </div>

 
       </div>
</>
    );
}


export function RTC_Blog_summery() {
    return (
        <div className="Blog_Summery" style={{ 
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #66be9e8f 85%), url(${backgroundImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}>
              <h2>Root Canal Treatment</h2>
                <p>
                If you were recently informed that you need a root canal treatment, chances are the minute you left your dentist's office, you immediately started searching the internet for information on this well-known but highly misunderstood dental service. While the internet is a great platform to learn new things and has lot of information it can be misleading as well. So let’s make it easier for you.    </p>
                <a href="/root-canal-blog">Read More</a>

       </div>
    );
}