export default function Orthodontics() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Orthodontics</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">












       
      <div className="cardsplitcard">
                <div className="contentone" style={{ display: 'grid', justifyContent: 'center' }}>
                   <h2>Can Children Get Braces?
                  </h2> <p>Yes Children Can Get Braces If Necessary. Most Get Braces Between 9 And 14 Years Old.
                  </p>    
                </div>
                   <div className="content_two">
                     <strong> 
                      Studies have shown that both children and their parents believe that orthodontics will help improve the child’s self-image, oral function and social life by correcting common dental problems such as crowded teeth and overbite in an early age.
                   </strong>
                       </div>
                   </div>
            
                <div className="cardsplitcard">
                <div className="contentone"style={{ display: 'grid', justifyContent: 'center' }}>
                    <h2>Can Adults Ger Braces?
                      </h2> 
                      <p>  Adults Benefit from Orthodontia as Well, And There’s No Right Or Wrong Age To Get Braces. As A Rule, Once the Adult Teeth Are In, Braces Are Possible.
                    </p>    
                </div>
                    <div className="content_two">
                      <strong> 
                      With modern technologies and materials used in Orthodontics today, it is never too late to consider braces as an adult. Braces are not only useful for cosmetic improvement but can also treat your jaw joint dysfunction. If you are planning to get crowns, veneers or other restorative treatments for either cosmetic or functional reasons, braces may be recommended to gain the maximum result. We go through your case thoroughly. We design a tailor made treatment plan using orthodontic analyst records such as x rays, intra oral and extra oral photographs and give you the best solution. Our orthodontic treatment has a high rate of success with many happy patients at the end of the treatment.  </strong>
                        </div>
                    </div>


                <div className="cardsplitcard">
                <div className="contentone" style={{ display: 'grid', justifyContent: 'center' }}>
                    <h2>What Are The Orthodontic Treatment Available At Havelock Dental Center:</h2> 
                      
                </div>
                    <div className="content_two">
                      <strong> 
                      • INVISALIGN<br/>
                      • Clear Aligners (In-Office)<br/>
                      • TRADITIONAL BRACES<br/>
                      • CERAMIC BRACES<br/>
                      • LINGUAL BRACES <br/>
                      </strong> 
                    
                    </div>
                    </div>
     
                <div className="card">
                  <h2>What Are Clear Aligners/ Invisalign?</h2>
                    <h3>Clear Aligners</h3>
                    <p>Clear aligners are one of the most popular treatment options for young adults because they offer a less-visible alternative to traditional orthodontic treatments (like braces). Clear aligners are made of a unique plastic material that’s customized to fit your teeth and look very similar to clear retainers. The most popular brands of in-office clear aligners are Invisalign. Invisalign still leads the industry as the “original clear aligner system.”

                    </p>
                  </div>  


           <div className="card">
            <h2>How do we get ALIGNERS?</h2>
            <p>The first step of your treatment is an evaluation of your teeth and gums. We will take intro oral scans, impressions, photos and x-rays of your teeth, which will help in designing your treatment plan. This step also helps to ensure there are no underlying issues that may complicate treatment. Then your impressions are sent to a dental lab to create a series of aligners specifically designed to correct your case.
              You’ll change aligners about once every 2 weeks. Over time, your teeth will shift and become more ideally aligned. Eventually, these changes will result in your new and improved smile. </p>
           </div>  


           <div className="cardsplitcard">
            <div className="contentone" style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }}  >
               <h2>What a re the Advantages Clear Aligners?
              </h2> 
              <strong>
                • Inconspicuous: Although not completely invisible, clear aligners offer a much more discreet option than more traditional treatments.<br/>
                • Low maintenance: Clear aligners are removable, which means it will be easier to properly brush and floss your teeth and maintain good oral hygiene.<br/>
                • Widely available: Invisalign is the leading brand of clear aligners and is offered by most dentists and orthodontists.<br/>
                • Comfort: You may experience some discomfort when switching aligners. However, the lack of brackets and wires means less irritation of the cheeks, lips, and gums.<br/>
              </strong>    
            </div>
               <div className="content_two" style={{padding:'15px'}}>
                <h2>What are the Disadvantages of In-Office Clear Aligners?
                </h2> 
                 <strong> 
                  • High cost: In-office clear aligners typically cost about the same or slightly more than traditional braces. Financing options are limited, and insurance usually does not cover clear aligner treatment.<br/>
                  • Limited time for eating: Clear aligners should be worn for 22 hours per day, so you may need to limit meal times or the number of times you eat per day.<br/>
                     </strong>
                   </div>
               </div>
               <div className="card">
                <h2>Who Is A Good Candidate For In-Office Clear Aligners?</h2>
                <p>In-office clear aligners are best for people who would like a less visible treatment option and who prefer to have treatment monitored by a dental professional. Invisalign and ClearCorrect work best for mild-to-moderate cases of teeth crowding/spacing, and mild-to-moderate bite issues (like underbite or crossbite).Clear aligners are best for people who can commit to wearing them for the full 22 hours per day. For this reason, frequent snackers or people who tend to take longer to eat their meals might need to change their habits or look into other options.
                </p>
               </div> 


               <div className="card">
                <h2>What are Traditional Braces?</h2>
                <p>Traditional braces are a classic for a reason: they’re the most comprehensive and effective treatment available. They are metal brackets that are fixed to the front surface of the teeth. A wire will be threaded through each bracket and tightened at various intervals throughout the treatment.
                  Given the rise of new, innovative orthodontic treatments, traditional braces often get a bad rap. However, there’s no reason to cross braces off your list.
                  Braces can treat a variety of complex misalignments and bite issues. So, they’re often recommended to people who aren’t considered a good fit for less conventional treatments, like home aligners or Invisalign. </p>
               </div> 


               <div className="cardsplitcard">
                <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }}  >
                   <h2>What are the Advantages of Traditional Braces?
                  </h2> 
                  <strong>
                    • Effective for complex cases: Traditional braces can treat even the most severe cases of misalignment or bite issues.<br/>
                    • Customizable: You’ll be able to choose the color of the bands on your brackets. Pick your favorite colors or opt for clear bands for a less visible option.<br/>
                    • Durable: Traditional braces are very strong. You won’t have to worry about your brackets chipping or cracking due to normal wear.<br/>
                    • Consistent: Traditional braces are attached to the teeth and can’t be removed at home. So, unlike clear aligners, you won’t have to worry about remembering to keep them in.<br/>
                  </strong>    
                </div>
                   <div className="content_two" style={{padding:'15px'}}>
                    <h2>What are the Disadvantages of Traditional Braces?
                    </h2> 
                     <strong> 
                      • Uncomfortable: Because of their brackets, traditional braces can cause some irritation to the cheeks and gums, especially during the beginning of treatment.<br/>
                      • Difficult to clean: Learning to brush and floss with braces can be a challenge. Food and plaque can get trapped in and around the brackets and wires fairly easily. So, it’s essential to have a good oral hygiene routine.<br/>
                      • Can’t eat certain foods: Sticky, chewy, and very crunchy foods can loosen or damage your wires and brackets. This may cause irritation or even prolong treatment.<br/>
                      • Very visible: Traditional metal braces are fairly noticeable. If you think you’ll be self-conscious or feel awkward with braces, they may not be an ideal choice.<br/>
                    </strong>
                       </div>
                   </div>
                   <div className="card">
                    <h2>Who Is A Good Candidate for Traditional Braces?</h2>
                    <p>Traditional braces are best for people who are looking for very comprehensive treatment and one that will correct moderate-to-severe misalignments and bite issues. If the idea of wearing braces as an adult makes you uncomfortable, you may be better off with a more discreet option.Likewise, if you don’t think you can commit to learning how to floss and brush around your braces, a lower maintenance option like clear aligners will likely be more suitable. However, if you don’t mind the look of braces and prefer to receive the most extensive treatment available, they could be a great choice.</p>
                   </div> 


                   <div className="card">
                    <h2>What are Ceramic Braces?</h2>
                    <p>Ceramic braces are very similar to traditional braces, but they have one significant advantage: ceramic braces have transparent or tooth-colored brackets instead of metal ones. Even the wires used for ceramic braces are offered in an off-white shade. Overall, they are a much more discreet option for young adults.Often called “clear braces,” ceramic braces can treat many of the same issues as traditional brackets. However, due to the more innovative material, ceramic braces tend to cause less discomfort and work slowly than traditional braces. So you’ll enjoy a quicker and more comfortable treatment. Unfortunately, ceramic braces typically cost more than traditional braces.

                    </p>
                   </div> 
    
    
                   <div className="cardsplitcard">
                    <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
                       <h2>What are Advantages of Ceramic Braces?
                      </h2> 
                      <strong>
                        • Less visible: Ceramic braces aren’t entirely invisible, but they’re much less noticeable than traditional braces<br/>
                        • More comfortable: Ceramic braces are usually more comfortable than traditional braces. The innovative material used for the brackets causes less irritation than metal.<br/>
                        • Comprehensive treatment: Like traditional braces, treatment with ceramic braces is overseen by an orthodontist and includes routine office visits to ensure quality treatment.<br/>
                      </strong>    
                    </div>
                       <div className="content_two" style={{padding:'15px'}}>
                        <h2>What are the Disadvantages of Ceramic Braces?
                        </h2> 
                         <strong> 
                          • Easily stained: Since they’re made of a clear material, ceramic braces can stain easily. It’s recommended that you avoid stain-inducing foods/drinks, such as red wine and coffee.<br/>
                        </strong>
                           </div>
                       </div>
                       <div className="card">
                        <h2>Who Is A Good Candidate for Ceramic Braces?</h2>
                        <p>Ceramic braces are best for people who prefer comprehensive treatment overseen by an orthodontist but want a less visible option. These braces can treat a wide variety of problems, including most cases of crowding and spacing, and even some bite issues. However, it’s also important to note the higher cost.If you think that the advantages of less visibility and discomfort will be worth the higher price tag, ceramic braces could be a great choice. Still, if you only have a mild-to-moderate case of misalignment, a cheaper option like Invisalign may be a better fit.

                        </p>
                       </div> 


                       <div className="card">
                        <h2>What are Lingual Braces?</h2>
                        <p>Lingual braces are the most hidden form of braces available. Although they have the same brackets and wires as traditional braces, lingual braces are placed on the back of the teeth as opposed to the front. Lingual braces are nearly invisible to the untrained eye. For people who don’t think ceramic braces will be discreet enough, they provide a great alternative.
                        </p>
                       </div> 
        
        
                       <div className="cardsplitcard">
                        <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
                           <h2>What are the Advantages of Lingual Braces?
                          </h2> 
                          <strong>
                            • Inconspicuous: Lingual braces are hidden behind the teeth and are virtually invisible.<br/>
                            • Customizable: Lingual braces can be customized to fit perfectly along the back of your teeth, reducing some discomfort.<br/>
                            • Consistent: Lingual braces are hidden, but attached to the teeth just like traditional braces. So, unlike clear aligners, lingual braces are constantly working to straighten your teeth.<br/>
                            • No visible staining: Since the brackets are only placed behind the teeth, any staining due to poor hygiene won’t be very noticeable. <br/>      
                          </strong>    
                        </div>
                           <div className="content_two" style={{padding:'15px'}}>
                            <h2>What are the Disadvantages of Lingual Braces?
                            </h2> 
                             <strong> 
                              • Cost: Lingual braces are much more expensive than traditional and ceramic braces.<br/>
                              • Irritation: Brackets on the back of the teeth tend to irritate the tongue and gums.<br/>
                              • Difficult to clean: Learning to brush and floss around your brackets may be challenging since they’re difficult to see. <br/>
                             </strong>
                               </div>
                           </div>
                           <div className="card">
                            <h2>Who Is A Good Candidate for Lingual Braces?</h2>
                            <p>Lingual braces are best for people who want the least visible form of braces possible. Although they are a pricey option, they are, no doubt, the most invisible. So, if aesthetics are your number one concern, lingual braces could be worth the high cost.Lingual braces are typically a good fit for most cases of crowding and spacing. However, more severe cases of misalignment or critical bite issues may place too much pressure on the brackets and cause them to pop off. For this reason, ideal candidates for lingual braces typically have less complicated issues to correct.
                            </p>
                           </div>          










      </div>
    </div>
    
    
    </>
    );
  }