import { Helmet } from "react-helmet";

export default function Restorative_Dentistry() {
    return (
      
<>
 <Helmet>
        {/* Page title */}
        <title>Restorative Dentistry | Havelock Dental</title>

        {/* Meta description */}
        <meta
          name="description"
          content="Learn about restorative dentistry and the procedures involved in repairing damaged oral structures. Find out the differences between restorative and cosmetic dentistry."
        />

        {/* Meta keywords (if applicable) */}
        <meta
          name="keywords"
          content="restorative dentistry, dental procedures, dental treatments, dental repairs, cosmetic dentistry, dental materials"
        />

        {/* Open Graph (OG) meta tags for Facebook */}
        <meta property="og:title" content="Restorative Dentistry | Havelock Dental" />
        <meta
          property="og:description"
          content="Learn about restorative dentistry and the procedures involved in repairing damaged oral structures. Find out the differences between restorative and cosmetic dentistry."
        />
        <meta property="og:url" content="http://havelockdental.com/Restorative-Dentistry" />
        {/* Add og:image meta tag with the URL of the image you want to use for sharing */}
        {/* <meta property="og:image" content="http://example.com/image.jpg" /> */}
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Restorative Dentistry | Havelock Dental" />
        <meta
          name="twitter:description"
          content="Learn about restorative dentistry and the procedures involved in repairing damaged oral structures. Find out the differences between restorative and cosmetic dentistry."
        />
        <meta name="twitter:url" content="http://havelockdental.com/Restorative-Dentistry" />
        {/* Add twitter:image meta tag with the URL of the image you want to use for sharing */}
        {/* <meta name="twitter:image" content="http://example.com/image.jpg" /> */}

        {/* Canonical URL */}
        <link rel="canonical" href="http://havelockdental.com/Restorative-Dentistry" />
      </Helmet>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Restorative Dentistry</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
        

      <div className="card">
      <h2>What Is Restorative Dentistry?</h2>
            <p>To put it simply, restorative dentistry involves any dental process which focuses on repairing or restoring damaged oral structures. This can include procedures such as fillings, bridges, and implants among many other services. It’s important to note, however, that restorative dentistry does not include those procedures that are considered cosmetic such as veneers or whitening services.
</p>
             </div>




           <div className="cardsplitcard">
            <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
            <h2>Differences Between Cosmetic & Restorative Dentistry?
              </h2> 
              <p>
              Cosmetic and restorative dentistry do have a lot in common. For starters, they are both used to restore damaged, decayed, or missing teeth. Additionally, they can both be performed by a general dentist, meaning that you will have no need to visit a specialist.

If cosmetic dentistry and restorative dentistry are so similar, how are the two differentiated, and what do these differences mean to when completing cosmetic and restorative procedures? In order to answer that question, here are the main differences between cosmetic and restorative dentistry: 
Restorative dental treatments are those that are performed in order to repair a broken, decayed, or missing tooth. Most people seek dental treatment because there is something wrong with their teeth. Often times, people experiencing dental pain, swelling, or bleeding seek dental care to alleviate these problems with restorative dental care.
Cosmetic dental treatments, on the other hand, are elective procedures used to alter the color, shape, size, alignment, and spacing of teeth. Most people seek cosmetic dental treatment as a result of not being happy with the appearance of their smile, rather than because they are experiencing symptoms. Therefore, while restorative dental treatments must be performed to alleviate symptoms and repair the tooth, cosmetic dental treatments do not have the same urgency.
The purpose of restorative dentistry is to restore function, while the purpose of cosmetic dentistry is to improve visual appearance. Restorative dental treatments focus on restoring decayed, damaged, or missing teeth in order to maintain proper function and oral health. An example of a commonly performed restorative procedure is a root canal followed by the placement of a dental crown. In this case, the dental crown is being used as a way to protect the remaining tooth structure from future decay or damage.
However, when a dental crown is used as a cosmetic dental treatment, its purpose is generally to improve the contour, color, or size of the tooth. Since cosmetic dental treatments focus on visual aesthetics, there are also a couple of treatments that are exclusively considered to be purely cosmetic, including professional teeth whitening and dental veneers. This is because neither of these treatments improves the function of the teeth and can only be performed on teeth that are overall healthy.   </p>        </div>
            <div className="content_two"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
            <h2>Another thing that sets restorative dentistry apart from cosmetic dentistry is the type of dental materials used to fabricate restorations</h2> 
                 <p> 
                
               Dental restorations used for restorative purposes are often made from materials that are durable and affordable, rather than aesthetically pleasing. One example of this is amalgam fillings, which are made by a mixture of metals.
Cosmetic dental materials on the other hand, offer durability along with aesthetics. Dental restorations used for cosmetic purposes are often selected for their color and translucent properties which allow the restoration to blend in with the surrounding teeth. One example of this would be composite or tooth-colored fillings, which are made using a dental composite resin that can be color-matched to blend with the remaining tooth structure.

As you can see, despite their similarities, restorative and cosmetic dentistry actually have a few key differences including treatment motivations, purposes, and their use of dental materials. Therefore, if you are looking for a specific type of dental treatment, you will want to communicate this with our dentist before agreeing to any procedure. It is also important to know that there are certain cases where both restorative and cosmetic dental procedures can be used in coordination with one another.
                </p>
            </div>
             
            </div>
    
    




      </div>
    </div>
    
    
    </>
    );
  }