export default function Endodontics() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Endodontics</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
        <div className="card">
          <h2>What is endodontics? </h2>
          <p>
            In the event that the pulp becomes inflamed or infected because of either deep decay, repeated dental procedures on the tooth, faulty crowns, or a crack or chip in the tooth, an endodontic treatment has to be performed. If left untreated, pulp inflammation can result in abscess. To understand this procedure, it helps to know about the anatomy of the tooth. A soft tissue known as the pulp is found under the white enamel and a hard layer that is our dentin. It contains blood vessels, nerves and connective tissue that help in the root growth of your tooth during development. The pulp becomes unnecessary once the tooth is fully developed as the tooth is nourished by the surrounding tissue.
          </p>
        </div>

        <div className="card">
          <h2>How Does Endodontic Treatment Save The Tooth?</h2>
          <p>
            During a root canal treatment, the inflamed or infected pulp is removed and the inside of the tooth is carefully cleaned and disinfected. It is then filled and sealed with a rubber-like material called gutta-percha. Afterwards, the tooth is restored for protection and a crown is placed if necessary. After restoration, the tooth will continue to function normally. Contrary to jokes about the matter, modern root canal treatment is very similar to having a routine filling and usually can be completed in one or two appointments; depending on the condition of your tooth and your personal circumstances. You can expect a comfortable experience during and after your appointment.
          </p>
        </div>

        <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
            <h2>What Are The Advantages Of Saving The Natural Tooth With A Root Canal Treatment?</h2>
            <p>
              Protects other teeth from excessive wear or strain Endodontic treatment helps you maintain your natural smile and limits the need for ongoing dental work. With proper care, most teeth that have had a root canal treatment can last as long as natural teeth; often for a complete lifetime.
            </p>
          </div>
          <div className="content_two">
            <strong>
              • Efficient chewing <br />
              • Normal biting force and sensation<br />
              • Natural appearance<br />
            </strong>
          </div>
        </div>
      </div>
    </div>
    
    
    </>
    );
  }