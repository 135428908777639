export default function Header() {

    return (
        
      <div className="nav">
      <a href="/" className="logo" >
          <img src="https://cdn.havelockdental.com/Havelock%20Dental%20Logo.svg" alt=""></img>
      </a>
    
        <input className="menu-icon" type="checkbox" id="menu-icon" name="menu-icon"/>
        <label htmlFor="menu-icon"></label>
        <nav className="nav"> 		
            <ul className="pt-5">
                
                <li><a href="/">Home</a></li>
                <li><a href="/Doctors">Doctors</a></li>
                <li><a href="/Treatments">Treatments</a></li>
                <li >
                  <a href="/invisalign">
                    Invisalign
                  </a>
                </li>

                <li><a href="/Brochures">Brochures</a></li>
                <li><a href="/Blogs">Blogs</a></li>
                <li><a href="/ImplantGuides">Surgical Guides</a></li>
                <li><a href="/Gallery">Gallery</a></li>
                <li><a href="https://quicklinks.havelockdental.com/">Contact</a></li>

            </ul>

        </nav>
       
     </div>

    )
}