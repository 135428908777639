import React from 'react';

function redirectToDesktopApp() {
  const name = (document.getElementById("Name") as HTMLInputElement).value;
  const contactNumber = (document.getElementById("Contact_Number") as HTMLInputElement).value;
  const email = (document.getElementById("Email") as HTMLInputElement).value;
  let message = "";

  if (name !== "" && contactNumber !== "") {
    if (email === "") {
      message = `Hi%2C%20I%20am%20${name}%20I%20would%20like%20to%20make%20an%20enquiry.%20My%20contact%20number%3A%20${contactNumber}`;
    } else {
      message = `Hi%2C%20I%20am%20${name}%20I%20would%20like%20to%20make%20an%20enquiry.%20My%20contact%20number%3A%20${contactNumber}%20And%20My%20Email%20${email}`;
    }
    window.open(`https://api.whatsapp.com/send?phone=94779414836&text=${message}`);
  } else if (name !== "" && contactNumber === "" && email !== "") {
    message = `Hi%2C%20I%20am%20${name}%20I%20would%20like%20to%20make%20an%20enquiry.%20My%20Email%20${email}`;
    window.open(`https://api.whatsapp.com/send?phone=94779414836&text=${message}`);
    return;
  } else if (name !== "" && contactNumber === "" && email === "") {
    message = `Hi%2C%20I%20am%20${name}%20I%20would%20like%20to%20make%20an%20enquiry.`;
    window.open(`https://api.whatsapp.com/send?phone=94779414836&text=${message}`);
    return;
  } else if (name === "" && contactNumber === "" && email !== "") {
    message = `%20I%20would%20like%20to%20make%20an%20enquiry.%20My%20Email%3A%20${email}`;
    window.open(`https://api.whatsapp.com/send?phone=94779414836&text=${message}`);
    return;
  } else {
    message = `%20I%20would%20like%20to%20make%20an%20enquiry.`;
    window.open(`https://api.whatsapp.com/send?phone=94779414836&text=${message}`);
  }
}

const CallBackrequest = () => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    redirectToDesktopApp();
  };
  return (
    <div className="Callback_request">
     <form onSubmit={handleSubmit}>
        <input type="text" id="Name" placeholder="Name" />
        <input type="number" id="Contact_Number" placeholder="Contact Number" />
        <input type="text" id="Email" placeholder="Email (Optional)" />
        <button type="submit" onClick={() => redirectToDesktopApp()} id="myButton">
          Request Call Back
        </button>
      </form>
    </div>
  );
};

export default CallBackrequest;
