import React from 'react';

const Directors = () => {
  return (
    <div className="directors">
      <div className="doc_cards">
        <div className="card_doc">
          <div className="docimg">
            <a href="/ImranMuhuseen">
              <img src="https://cdn.havelockdental.com/Images/Doctors/Dr.%20Imran.%20M.%20Muhuseen.png" alt="Doctor's Image"/>
            </a>
          </div>
          <div className="doccontent">
            <h2>Dr. Imran. M. Muhuseen</h2>
            <h2>Director</h2>
            <h1>BDS, FICCDE (Orthodontics), MCGDP</h1>
          <br />
            <a href="mailto:imran@havelockdental.com">imran@havelockdental.com</a>
          </div>
          <div className="Doc_Extras">
            <p>
              17 years of Clinical Experience; Graduate of Liaquat University of Medical and Health Sciences, Pakistan.
              Present Secretary of Sri-Lanka Academy of Aesthetic and Cosmetic Dentistry.
              Completed continuing education courses in Implantology, Digital Dentistry, Clinical Occlusion Orthodontics, Aesthetic Dentistry & Preventive Care.
            </p>
          </div>
        </div>
        <div className="card_doc">
          <div className="docimg">
            <a href="/TharanginiAsvinash">
              <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/5c253821-56f0-4448-0728-cd7872916600/public" alt="Doctor's Image"/>
            </a>
          </div>
          <div className="doccontent">
            <h2>Dr. Tharangini. R. Asvinash</h2>
            <h2>Director</h2>
            <h1>BDS, FICCDE (Orthodontics)</h1>
            <a href="tel:+94777347570">+94 (77) 734 7570</a><br />
            <a href="mailto:tharangini@havelockdental.com">tharangini@havelockdental.com</a>
          </div>
          <div className="Doc_Extras">
            <p>
              8 years of Clinical Experience. 
              Graduate of Saveetha Dental College and Hospitals, India.
              Member of Sri-Lanka Dental Association.
              Completed continuing education courses in Implantology, Digital Dentistry, Clinical Occlusion, Orthodontics & Aesthetic Dentistry
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directors;
  /*<a href="tel:+94714039818">+94 (71) 403 9818</a><br/>
  */