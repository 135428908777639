import { Route, Routes } from "react-router-dom";

import './Styles/Style.css';





import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Doctors from "./Pages/Doctors";
//pages
import Home from "./Pages/home";
import Gallery from "./Pages/Gallery";
import Treatments from "./Pages/Treatments";

//treatments

import Endodontics from "./Pages/Treatments/Endodontics";
import Preventive_Dentistry from "./Pages/Treatments/Preventive Dentistry";
import Cosmetic_Aesthetic_Dentistry from "./Pages/Treatments/Cosmetic Aesthetic Dentistry"
import Dental_Implants from "./Pages/Treatments/Dental Implants"
import Orthodontics from "./Pages/Treatments/Orthodontics"
import Prosthodontics from "./Pages/Treatments/Prosthodontics"
import Mobile_Dental_Services from "./Pages/Treatments/Mobile Dental Services"
import Periodontics from "./Pages/Treatments/Periodontics"
import Pedodontic from "./Pages/Treatments/Pedodontic"
import Restorative_Dentistry from "./Pages/Treatments/Restorative Dentistry"
import Invisalign from "./Pages/Treatments/Invisalign/Invisalign";
//services
import Implant_Surgical_Guides from "./Pages/Services/Digital Dentistry/Implant Surgical Guides"

import {RTC_Blog} from "./Pages/Blogs/RTC_Blog/RTC_Blog";
import {Teeth_Whitening_Blog} from "./Pages/Blogs/Teeth_Whitening/Teeth_Whitening_Blog";
import {Bridge_Blog} from "./Pages/Blogs/Bridge_Blog/Bridge_Blog";
import {Crown_Blog} from "./Pages/Blogs/Crown_Blog/Crown_Blog";
import {Test_Blog} from "./Pages/Blogs/TestBlog/Test_Blog";
import { Navigating_Dental_Implant_Pain_Blog } from "./Pages/Blogs/Navigating_Dental_Implant_Pain/Navigating_Dental_Implant_Pain";
import { Root_Canal_On_Front_Tooth_Blog } from "./Pages/Blogs/Root_Canal_On_Front_Tooth/Root_Canal_On_Front_Tooth";
import Blogs from "./Pages/Blogs/summary";
import {Understanding_Managing_teeth_sensitivity_Blog} from "./Pages/Blogs/Understanding_Managing_teeth_sensitivity/Understanding_Managing_teeth_sensitivity"
import {Air_Polishing_Blog} from "./Pages/Blogs/Air_Polishing/Air_Polishing"

import { Bite_Correction_Blog } from "./Pages/Blogs/Bite_Correction/Bite_Correction";
import { Dental_Veneers_Blog } from "./Pages/Blogs/Dental_veneers/Dental_veneers";

import { Invisalgin_Blog } from "./Pages/Blogs/Invisalign/Invisalign";

import { Invisalign_Jr_Blog } from "./Pages/Blogs/Invisalign_Jr/Invisalign_Jr";
//doctors 
import Dr_Imran_Muhuseen from "./Pages/Doctors/Dr_Imran_Muhuseen";
import Dr_Tharangini_R_Asvinash from "./Pages/Doctors/Dr_Tharangini_R_Asvinash";
import Dr_Rifeth_Rasheed from "./Pages/Doctors/Dr_Rifeth_Rasheed";
import Dr_Tony_Muthumala from "./Pages/Doctors/Dr_Tony_Muthumala";
import Branches from "./Pages/Branches/Branches";

import Brouchers from "./Pages/Brouchers/Brouchers";
// /  <Route path="/Test" element={<Test />} />
export default function App() {


  return(
    <>
<Header/>



  
  <Routes>
  <Route path="/" element={<Home />} />


  <Route path="/Brouchers" element={<Brouchers />} />
  <Route path="/Brochures" element={<Brouchers />} />
  
  <Route path="/Branches" element={<Branches />} />
  
  <Route path="/invisalign_" element={<Invisalign />} />
  <Route path="/Blogs" element={<Blogs />} />

  <Route path="/invisalign-jr"  element={<Invisalign_Jr_Blog />} />
  <Route path="/invisalign"  element={<Invisalgin_Blog />} />
  <Route path="/Understanding-Managing-teeth-sensitivity-Blog"  element={<Understanding_Managing_teeth_sensitivity_Blog />} />
  <Route path="/Navigating-Dental-Implant-Pain-Blog"  element={<Navigating_Dental_Implant_Pain_Blog />} />
  <Route path="/Root-Canal-On-Front-Tooth-Blog" element={<Root_Canal_On_Front_Tooth_Blog />} />
  <Route path="/Air-Polishing-Blog" element={<Air_Polishing_Blog />} />

  <Route path="/Bite-Correction-blog" element={<Bite_Correction_Blog />} />
  <Route path="/Dental-Veneers-blog" element={<Dental_Veneers_Blog />} />
  <Route path="/root-canal-blog" element={<RTC_Blog />} />
  <Route path="/Teeth-Whitening-blog" element={<Teeth_Whitening_Blog />} />
  <Route path="/Bridge-blog" element={<Bridge_Blog />} />
  <Route path="/Crown-blog" element={<Crown_Blog />} />
  <Route path="/Test_Blog" element={<Test_Blog />} />


  <Route path="/Doctors" element={<Doctors />} />
  <Route path="/Gallery" element={<Gallery />} />
  <Route path="/Treatments" element={<Treatments />} />
  <Route path="/ImplantGuides" element={<Implant_Surgical_Guides />} />

  <Route path="/ImranMuhuseen" element={<Dr_Imran_Muhuseen />} />
  <Route path="/TharanginiAsvinash" element={<Dr_Tharangini_R_Asvinash />} />
  <Route path="/RifethRasheed" element={<Dr_Rifeth_Rasheed />} />
  <Route path="/TonyMuthumala" element={<Dr_Tony_Muthumala />} />


    <Route path="/Restorative Dentistry" element={<Restorative_Dentistry />} />
    <Route path="/Pedodontic" element={<Pedodontic />} />
    <Route path="/Periodontics" element={<Periodontics />} />
    <Route path="/Mobile Dental Services" element={<Mobile_Dental_Services />} />
    <Route path="/Prosthodontics" element={<Prosthodontics />} />
    <Route path="/Orthodontics" element={<Orthodontics />} />
    <Route path="/Endodontics" element={<Endodontics />} />
    <Route path="/Dental Implants" element={<Dental_Implants />} />
    <Route path="/Cosmetic Aesthetic Dentistry" element={<Cosmetic_Aesthetic_Dentistry />} />
    <Route path="/Preventive Dentistry" element={<Preventive_Dentistry />} />
    <Route path="/Endodontics" element={<Endodontics />} />

  </Routes>


   
<Footer/>


</>

  )
}
 
/*
  <Route path="/ZakiyaKamarzaman" element={<Dr_Zakiya_Kamarzaman />} />

*/