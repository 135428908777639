
import { Helmet } from "react-helmet";

export default function Dr_Tony_Muthumala() {
    return (
      
<>
<Helmet>

  <title>Dr. Tony Muthumala - Dental Surgeon</title>
  <meta name="description" content="Learn about Dr. Tony Muthumala's qualifications, experience, and passion for dentistry. Contact him for quality dental care at his clinic." />
  <meta name="keywords" content="Dr. Tony Muthumala, dental surgeon, dental care, dental implantology, maxillofacial trauma, orthognathic surgery, cleft lip and palate surgery, head and neck cancer surgery" />
  <meta property="og:title" content="Dr. Tony Muthumala - Dental Surgeon" />
  <meta property="og:description" content="Learn about Dr. Tony Muthumala's qualifications, experience, and passion for dentistry. Contact him for quality dental care at his clinic." />
  <meta property="og:image" content="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/b1274c40-e35c-4d8c-a944-48df8dca0c00/public" />
  <link rel="canonical" href="/TonyMuthumala"/>
  <meta property="og:url" content="https://havelockdental.com/TonyMuthumala" />
</Helmet>
<div className="doctorPagewrapper">
    <div className="doctor">
        <div className="card">
            <div className="docimg">
            <a href="#">
                <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/b1274c40-e35c-4d8c-a944-48df8dca0c00/public" alt=""/>
            </a>
            </div>
            <div className="doccontent">
            <h2>Dr. Tony </h2><h2 className="outline"> Muthumala</h2>
                    <h2>Dental Surgeon</h2>
                    <h1>
                        MS (Oral and Maxillofacial Surgery), FFDRCS (Ireland)
                    </h1>

            </div>
            <div className="Doc_Extras">
            <p>
            Dr. Tony Muthumala is a highly experienced dental surgeon with over 37 years of experience as a consultant. He is a specialist in oral and maxillofacial surgery and holds a Master of Surgery (MS) degree in the field. He is also a Fellow of the Faculty of Dentistry of the Royal College of Surgeons in Ireland (FFDRCS).
            </p>
<br />
            <p>
Dr. Muthumala has worked as a consultant craniofacial surgeon for the Sri Lankan Army, where he provided specialized care to patients with facial injuries and deformities. He has also served as a consultant oral and maxillofacial surgeon at several hospitals in Sri Lanka, including Nawaloka and Lanka Hospitals.</p>
<br />
<p>
As a highly respected dental surgeon, Dr. Muthumala has extensive experience in diagnosing and treating a wide range of dental and maxillofacial conditions. His areas of expertise include dental implantology, maxillofacial trauma and reconstruction, orthognathic surgery, cleft lip and palate surgery, and head and neck cancer surgery.
</p>
<br />
<p>
Dr. Muthumala is known for his compassionate approach to patient care, and he works closely with his patients to develop personalized treatment plans that meet their unique needs and goals. He also stays up to date with the latest advancements in his field and is committed to providing his patients with the best possible care using state-of-the-art technology and techniques.
</p>

<br />
<p>
Overall, Dr. Tony Muthumala is a highly skilled and experienced dental surgeon who is dedicated to providing his patients with the highest quality care. His expertise and compassionate approach have earned him a reputation as one of the leading dental surgeons in Sri Lanka.

                    </p>
            </div>
        </div>
    </div>
   </div>

    
    </>
    );
  }