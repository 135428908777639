import { useEffect } from "react";

function animateValue(id, start, end, duration) {
  if (start === end) return;
  var range = end - start;
  var current = start;
  var increment = end > start ? 1 : -1;
  var stepTime = Math.abs(Math.floor(duration / range));
  var obj = document.getElementById(id);
  var timer = setInterval(function () {
    current += increment;
    obj.innerHTML = current + "+";
    if (current === end) {
      clearInterval(timer);
    }
  }, stepTime);
}

export default function LiveCount() {
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5 // Intersection ratio threshold for when the element is considered visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const countId = entry.target.id;
          let start, end;
          switch (countId) {
            case "count1":
              start = 0;
              end = 1000;
              break;
            case "count2":
              start = 0;
              end = 2500;
              break;
            case "count3":
              start = 0;
              end = 500;
              break;
            case "count4":
              start = 0;
              end = 2500;
              break;
            default:
              start = 0;
              end = 0;
          }
          animateValue(countId, start, end, 100);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const targets = document.querySelectorAll(".count_Item p");
    targets.forEach((target) => {
      observer.observe(target);
    });
  }, []);

  return (
    <div className="livecount">
      <a href="/Dental Implants">
        <div className="count_Item">
          <div className="img">
            <img src="https://cdn.havelockdental.com/images/icons/Group_2659.png" alt="" />
          </div>
          <div className="content">
            <p>Implants</p>
            <p id="count1">0+</p>
          </div>
        </div>
      </a>
      <a href="/Endodontics">
        <div className="count_Item">
          <div className="img">
            <img src="https://cdn.havelockdental.com/images/icons/Group_2661.png" alt="" />
          </div>
          <div className="content">
            <p>Endodontics</p>
            <p id="count2">0+</p>
          </div>
        </div>
      </a>
      <a href="/Orthodontics">
        <div className="count_Item">
          <div className="img">
            <img src="https://cdn.havelockdental.com/images/icons/Group_2673.png" alt="" />
          </div>
          <div className="content">
            <p>Orthodontics</p>
            <p id="count3">0+</p>
          </div>
        </div>
      </a>
      <a href="/Prosthodontics">
        <div className="count_Item">
          <div className="img">
            <img src="https://cdn.havelockdental.com/images/icons/Group_2679.png" alt="" />
          </div>
          <div className="content">
            <p>Prosthodontics</p>
            <p id="count4">0+</p>
          </div>
        </div>
      </a>
    </div>
  );
}
