export default function Mobile_Dental_Services() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Mobile Dental Services</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">


      <div className="cardsplitcard">
          <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
             <h2>What is Mobile Dental services?
            </h2> 
            <p>
              Regular teeth cleaning can prevent cavities, reduce gum diseases, stop tooth loss, brighten your smile and boost your overall health.
              Our Mobile Dental Service brings quality oral health care to Colombo wherever they are.
              Utilizing only superior quality portable equipment, Mobile Dental Service travels where we are needed to offer benefits of consistent and quality dental services.
            </p>    
          </div>
             <div className="content_two" style={{padding:'15px'}}>
              <h2> We serve: 
              </h2> 
               <strong> 
               <br/>Private homes, Senior residencies and businesses.
                Our services include: Examination, Cleaning (including periodontal/gum disease treatment), Fillings and other restorative dental services, Denture creation, Extractions and Fluoride treatment.

              </strong>
                 </div>
             </div>
  







           <div className="cardsplitcard">
            <div className="contentone" style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
               <h2>Some of the Benefits of Mobile Dental : </h2> 
              <strong>
                • Treating patients in their comfort zone close to home, at work, school, retirement communities, business parks, parking lots of retail centers and more.<br/>
• Mobile units serve as a billboard on wheels, helping to promote the institution and a sense of security to the community.<br/>
• Patients receive accurate, efficient and friendly quality of treatment from trained and certified medical staff.<br/>
• Technologically advanced equipment providing most of the same services as fixed dental clinics.<br/>
• Mobile dentistry programs mediate financial barriers, accessibility and psychological obstructions to the populations they serve.<br/>
              </strong>        
            </div>
             
               </div>








      </div>
    </div>
    
    
    </>
    );
  }