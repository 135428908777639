
import "./Style/Style.css"
export default function Invisalign(){

    return(
       <div className="invisalign_page">


       <div className="image">
        image
       </div>

<div className="whatisinvis">
<h2>What is Invisalign?</h2>
<p>Invisalign is a type of clear aligner, a form of orthodontic device that is used to adjust teeth. Unlike traditional braces that use metal wires and brackets, Invisalign aligners are made of a flexible plastic material, specifically, a patented thermoplastic material called SmartTrack® created exclusively for Invisalign treatment.</p>
</div>

<div className="differences">

        <div className="braces">
            <h2>Traditional Braces</h2>
            <p>Traditional braces have been around for decades and are made up of metal brackets and wires that are attached to the teeth. They are highly effective in treating a range of dental issues, especially more complex cases.</p>
        </div>

        <div className="invisalign">
            <h2>Invisalign®</h2>
            <p>
            Invisalign® is a modern orthodontic treatment that uses clear, removable aligners to straighten teeth. These aligners are custom-made for each patient and are replaced every couple of weeks.
            </p>
        </div>


</div>

<div className="invis_advantages">

    <h2>Advantages of Invisalign</h2>

    <ul>
        <li><strong>Discreet :</strong> Invisalign braces are highly discreet, allowing patients to smile freely without feeling self-conscious.</li>
        <li><strong>Comfort :</strong> Invisalign aligners are more comfortable than traditional braces, which can sometimes cause small abrasions in the mouth or make eating feel painful after an adjustment.</li>
        <li><strong>Predictive Software :</strong> Invisalign software allows patients to see the predicted outcome of their treatment, boosting motivation for some.</li>
    </ul>

</div>

<div className="technology">
<h2>The Technology Behind Invisalign</h2>
    <p>Invisalign is a device that is both hypoallergenic and biologically stable. It's crafted from a medical-grade polymer that boasts strength and flexibility. The treatment plan, including the course of treatment from beginning to end, is meticulously designed using advanced 3-D computer imaging. The strategic placement of the Invisalign® aligners ensures the correct adjustment of teeth.</p>

</div>

       </div>
    )
}