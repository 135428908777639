import "./Style/Style.css"


import React from 'react';

interface BranchProps {
  imageUrl: string;
  location: string;
  redirectUrl : string;
}
export default function Branches(){
    return(
    

<div className="Branches_Main">
<br />
<h2>Our Dental Clinic Locations In Sri Lanka</h2>
<br />

<div className="Branches_Wrapper">


<Branch redirectUrl="https://maps.app.goo.gl/waQnHhBVkbDsyH9s9" imageUrl="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/03fed140-72dc-4f14-e63c-d20712c1f100/public" location="Colombo 07"/>

<Branch  redirectUrl="https://maps.app.goo.gl/UycCRfqxr4ncnVPR9" imageUrl="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/900d110f-7e3c-422f-fdc4-75215e228f00/public" location="Boralesgamuwa"/>

    </div>
    <br />
</div>
    
    
    )
}


const Branch: React.FC<BranchProps> = ({ imageUrl, location ,redirectUrl }) => {
  const divStyle: React.CSSProperties = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <a target="_blank" href={redirectUrl} className="Branch" style={divStyle}>
      <p className="Location">{location}</p>
    </a>
  );
};


