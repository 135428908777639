

import { Helmet } from "react-helmet";
export default function Dr_Imran_Muhuseen() {
    return (
      
<>
<Helmet>
  <title>Dr. Imran Muhuseen - Director at Havelock Dental</title>
  <meta name="description" content="Learn about Dr. Imran Muhuseen's qualifications, experience, and passion for dentistry. Contact him for quality dental care at Havelock Dental." />
  <meta name="keywords" content="Dr. Imran Muhuseen, Havelock Dental, dental care, aesthetic dentistry, orthodontics, implantology, digital dentistry, preventive care" />
  <meta property="og:title" content="Dr. Imran Muhuseen - Director at Havelock Dental" />
  <meta property="og:description" content="Learn about Dr. Imran Muhuseen's qualifications, experience, and passion for dentistry. Contact him for quality dental care at Havelock Dental." />
  <meta property="og:image" content="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/e945d256-d13e-4096-c158-b0bdb2d09f00/public" />
  <link rel="canonical" href="/ImranMuhuseen"/>
  <meta property="og:url" content="https://havelockdental.com/ImranMuhuseen" />
</Helmet>

<div className="doctorPagewrapper">
    <div className="doctor">
        <div className="card">
            <div className="docimg">
            <a href="#">
                <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/e945d256-d13e-4096-c158-b0bdb2d09f00/public" alt=""/>
            </a>
            </div>
            <div className="doccontent">
            <h2>Dr. Imran. M.</h2><h2 className="outline"> Muhuseen</h2>
            <h2>Director</h2>

            <h1>BDS, FICCDE (Orthodontics), MCGDP</h1>
            <a href="mailto:imran@havelockdental.com">imran@havelockdental.com</a>
    
            </div>
            <div className="Doc_Extras">
                <p>
                Dr. Imran Muhuseen is a highly experienced dental professional with over 17 years of clinical experience in the field. He graduated from Liaquat University of Medical and Health Sciences in Pakistan in 2004 and completed his internship there from 2004 to 2005. He later returned to his hometown of Colombo to take over his father's dental practice and has since then transformed it into a modern and technologically advanced facility.
                </p>
                
                <br/>
                <br/>
                <p>Dr. Imran has a passion for continuing education, and he has dedicated numerous hours to staying up-to-date with the latest advances in aesthetic dentistry, orthodontics, implantology, digital dentistry, and preventive care. He has completed continuing education courses in various aspects of dentistry, including Implantology, Digital Dentistry, Clinical Occlusion Orthodontics, Aesthetic Dentistry, and Preventive Care.</p>
                <br/>
                <br/>
               <p>Dr. Imran is an active board member of the College of General Dental Practitioners and currently serves as the president of the Sri Lanka Academy of Aesthetic and Cosmetic Dentistry. He has been involved in organizing several conferences and workshops for continuing dental education.</p>
               <br/>
               <br/>
               <p>Dr. Imran's training includes a two-year program in complex orthodontics and clear aligners, conducted by the US-based organization Progressive Orthodontic Seminars (POS). He earned the Fellowship in College of Continuing Dental Education in orthodontics awarded by the Asia Pacific Dental Congress in 2015.</p>
               <br/>
               <br/>
               <p>Aside from his professional accomplishments, Dr. Imran is also an alumni of Royal College Colombo and has been an active sportsman. He has represented his college in Rugby and earned a brown belt in karate. Dr. Imran is passionate about traveling and spends quality time with his wife Farhath and their four children, Rahma, Hana, Ahmed, and Aadham.</p>
              
                
            </div>
        </div>
    </div>
   </div>

    
    </>
    );
  }
  /*<a href="tel:+94714039818">+94 (71) 403 9818</a><br/>
  */