

import { Helmet } from "react-helmet";
export default function Dr_Rifeth_Rasheed() {

    return (
      
<>
<Helmet>
  <title>Dr. Rifeth H. Rasheed - Dental Surgeon at Havelock Dental</title>
  <meta name="description" content="Learn about Dr. Rifeth H. Rasheed's qualifications, experience, and passion for dentistry. Contact him for quality dental care at Havelock Dental." />
  <meta name="keywords" content="Dr. Rifeth H. Rasheed, Havelock Dental, dental care, cosmetic dentistry, orthodontics, implantology, digital dentistry, general dentistry" />
  <meta property="og:title" content="Dr. Rifeth H. Rasheed - Dental Surgeon at Havelock Dental" />
  <meta property="og:description" content="Learn about Dr. Rifeth H. Rasheed's qualifications, experience, and passion for dentistry. Contact him for quality dental care at Havelock Dental." />
  <meta property="og:image" content="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/dfe545d1-6e17-41ad-ce3e-3ed1a170ae00/public" />
  <link rel="canonical" href="/RifethRasheed"/>
  <meta property="og:url" content="https://havelockdental.com/RifethRasheed" />
</Helmet>
<div className="doctorPagewrapper">
    <div className="doctor">
        <div className="card">
            <div className="docimg">
            <a href="#">
                <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/dfe545d1-6e17-41ad-ce3e-3ed1a170ae00/public" alt=""/>
            </a>
            </div>
            <div className="doccontent">
            <h2>Dr.Rifeth.</h2><h2 className="outline"> H. Rasheed</h2>
                        <h2>Dental Surgeon</h2>
                        <h1>BDS, FICCDE (Orthodontics),<br/>MCGDP</h1>

                     <a href="tel:+94779415572">+94 (77) 941 5572</a><br/>
                                <a href="mailto:rifeth@havelockdental.com">rifeth@havelockdental.com</a>
                    
            </div>
            <div className="Doc_Extras">
            <p>
            Dr. Rifeth H. Rasheed is a highly experienced dental surgeon with 20 years of clinical experience. He earned his Bachelor of Dental Surgery (BDS) degree from Rajiv Gandhi University of Health Sciences in India. He also completed a fellowship program in FICCDE (Fellowship in International College of Continuing Dental Education) in Orthodontics awarded by the Asia Pacific Dental Congress. and a postgraduate diploma in General Dental Practice (MCGDP) From the College of General Dental Practitioners of Sri Lanka.
                 </p>  
                  <br/>
                <br/>
                 <p>Dr. Rasheed is a well-respected member of the dental community and has held various leadership positions throughout his career. He has served as the President of the Sri-Lanka Academy of Aesthetic and Cosmetic Dentistry and is a member of the American Academy of Implant Dentistry (AAID).</p>
                 <br/>
                <br/>
                <p>As a dental surgeon, Dr. Rasheed has extensive experience in a wide range of dental procedures, including cosmetic dentistry, orthodontics, implantology, Digital dentistry and general dentistry. He is committed to providing his patients with the highest quality of care, and his patients appreciate his gentle, compassionate approach.</p>
                <br/>
                <br/>
                <p>Dr. Rasheed is dedicated to staying up to date with the latest advancements in his field and frequently attends continuing education courses and conferences. He is particularly interested in cosmetic dentistry and has extensive experience in teeth whitening, porcelain veneers, and other cosmetic procedures.</p>
                <br/>
                <br/>
                <p>In addition to his professional accomplishments, Dr. Rifeth is a dedicated family man who enjoys spending time with his wife Nashath and their three sons Raiyan, Haroun and Raaidh. Outside of dentistry, he has a keen interest in traveling and aviation, and martial arts.</p>
                <br/>
                <br/>
                <p>
                Overall, Dr. Rifeth H. Rasheed is a highly skilled and experienced dental surgeon who is committed to providing his patients with the best possible care. His expertise and dedication to his patients have earned him a reputation as one of the leading dental surgeons in Sri Lanka.
                </p>
            </div>
        </div>
    </div>
   </div>

    
    </>
    );
  }