import react, { Fragment } from 'react';  

import { RTC_Blog_summery } from './RTC_Blog/RTC_Blog';
import { Teeth_Whitening_Blog_summery } from './Teeth_Whitening/Teeth_Whitening_Blog';
import { Bridge_Blog_summery } from './Bridge_Blog/Bridge_Blog';
import { Crown_Blog_summery } from './Crown_Blog/Crown_Blog';
import { Air_Polishing_Blog_summery } from './Air_Polishing/Air_Polishing';
import { Root_Canal_On_Front_Tooth_Blog_summery } from './Root_Canal_On_Front_Tooth/Root_Canal_On_Front_Tooth';
import {Navigating_Dental_Implant_Pain_Blog_summery} from './Navigating_Dental_Implant_Pain/Navigating_Dental_Implant_Pain'
import {Test_Blog_summery} from './TestBlog/Test_Blog';
import { Dental_Veneers_Blog_summery } from './Dental_veneers/Dental_veneers';
import { Understanding_Managing_teeth_sensitivity_Blog_summery } from './Understanding_Managing_teeth_sensitivity/Understanding_Managing_teeth_sensitivity';
import { Bite_Correction_Blog_summery } from './Bite_Correction/Bite_Correction';
import { Invisalgin_Blog_summery } from './Invisalign/Invisalign';
import { Invisalign_Jr_Blog_summery } from './Invisalign_Jr/Invisalign_Jr';
export default function Blogs() {

    return (
<>
<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Blogs</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>
<div className='Summery_Wrapper'>


<Invisalgin_Blog_summery/>
<Invisalign_Jr_Blog_summery/>
<RTC_Blog_summery/>
<Teeth_Whitening_Blog_summery/>
<Bridge_Blog_summery/>
<Crown_Blog_summery/>
<Air_Polishing_Blog_summery/>
<Root_Canal_On_Front_Tooth_Blog_summery/>
<Navigating_Dental_Implant_Pain_Blog_summery/>
<Understanding_Managing_teeth_sensitivity_Blog_summery/>
<Dental_Veneers_Blog_summery/>
<Bite_Correction_Blog_summery/>
{
    /*
    <Test_Blog_summery/>
<RTC_Blog_summery/>
    */
}

        </div>



</>
    )
}