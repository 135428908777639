import React from 'react';
import Branches from '../Pages/Branches/Branches';

const AboutUs = () => {
  return (
    <div className="aboutus">
          <div className="heading">
            <h1>Havelock Dental Center</h1>
          </div>
     
          <div className="detail">
            <p>
              Havelock Dental Centre provides the best dental treatment in Sri Lanka, that uses 
              world-className dental techniques in state-of-the-art facilities. We offer the most comprehensive oral health solutions on par with international 
              standards, all under one roof.
              we Have been in operation since 1970, serving 
              thousands of happy patients over 52 years. 
               </p>
          </div>
          <Branches/>
        </div>
  );
};

export default AboutUs;
