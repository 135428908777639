

export default function Doctors() {
    return (
      
<>
<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/c7e582d3-a7b6-46e2-a712-99a8027d3400/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Our Doctors</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
      <div className="directors">
      <div className="doc_cards">
        <div className="card_doc">
          <div className="docimg">
            <a href="/ImranMuhuseen">
              <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/e945d256-d13e-4096-c158-b0bdb2d09f00/public" alt="Doctor's Image"/>
            </a>
          </div>
          <div className="doccontent">
            <h2>Dr. Imran. M. Muhuseen</h2>
            <h2>Director</h2>
            <h1>BDS, FICCDE (Orthodontics), MCGDP</h1>
            <a href="mailto:imran@havelockdental.com">imran@havelockdental.com</a>
          </div>
          <div className="Doc_Extras">
            <p>
              17 years of Clinical Experience; Graduate of Liaquat University of Medical and Health Sciences, Pakistan.
              Present Secretary of Sri-Lanka Academy of Aesthetic and Cosmetic Dentistry.
              Completed continuing education courses in Implantology, Digital Dentistry, Clinical Occlusion Orthodontics, Aesthetic Dentistry & Preventive Care.
            </p>
          </div>
        </div>
        <div className="card_doc">
          <div className="docimg">
            <a href="/TharanginiAsvinash">
              <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/5c253821-56f0-4448-0728-cd7872916600/public" alt="Doctor's Image"/>
            </a>
          </div>
          <div className="doccontent">
            <h2>Dr. Tharangini. R. Asvinash</h2>
            <h2>Director</h2>
            <h1>BDS, FICCDE (Orthodontics)</h1>
            <a href="tel:+94777347570">+94 (77) 734 7570</a><br />
            <a href="mailto:tharangini@havelockdental.com">tharangini@havelockdental.com</a>
          </div>
          <div className="Doc_Extras">
            <p>
              8 years of Clinical Experience. 
              Graduate of Saveetha Dental College and Hospitals, India.
              Member of Sri-Lanka Dental Association.
              Completed continuing education courses in Implantology, Digital Dentistry, Clinical Occlusion, Orthodontics & Aesthetic Dentistry
            </p>
          </div>
        </div>





     
        <div className="card_doc">
          <div className="docimg">
            <a href="/RifethRasheed">
              <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/dfe545d1-6e17-41ad-ce3e-3ed1a170ae00/public" alt="Doctor's Image"/>
            </a>
          </div>
          <div className="doccontent">
            <h2>Dr.Rifeth. H. Rasheed</h2>
            <h2>Dental Surgeon</h2>
            <h1>BDS, FICCDE (Orthodontics),<br/>MCGDP</h1>
          </div>
          <div className="Doc_Extras">
            <p>
            20 years of Clinical Experience. 
            Graduate of Rajiv Gandhi University of Health Sciences, India.
            Past President of Sri-Lanka Academy of Aesthetic and Cosmetic Dentistry.
            Member of American Academy of Implant Dentistry (AAID).       
           
           </p>
          </div>
        </div>
     
        <div className="card_doc">
          <div className="docimg">
            <a href="/TonyMuthumala">
              <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/b1274c40-e35c-4d8c-a944-48df8dca0c00/public" alt="Doctor's Image"/>
            </a>
          </div>
          <div className="doccontent">
            <h2>Dr. Tony Muthumala</h2>
            <h2>Dental Surgeon</h2>
            <h1>MS (Oral and Maxillofacial Surgery), FFDRCS (Ireland)</h1>
          </div>
          <div className="Doc_Extras">
            <p>
            37 year of experience as Consultant.
            Consultant Craniofacial Surgeon- Sri-Lanka Army.
            Consultant Oral and Maxillofacial 
            surgeon at Nawaloka and Lanka Hospitals
            </p>
          </div>
        </div>


        <div className="card_doc">
          <div className="docimg">
            <a href="/ZakiyaKamarzaman">
              <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/93daef7c-9deb-4b80-f770-8c9bcfda0200/public" alt="Doctor's Image"/>
            </a>
          </div>
          <div className="doccontent">
            <h2>Dr. Zakiya Kamarzaman</h2>
            <h2>Dental Surgeon</h2>
            <h1>BDS</h1><br></br>
          </div>
          <div className="Doc_Extras">
            <p>
            4 years of clinical experience.
                                        Graduate of University of Peradeniya, Sri-Lanka.
                                        Visiting General Dentist at Ninewells 
                                        Hospital.
            </p>
          </div>
        </div>










    

      </div>
    </div>












      </div>
    </div>

    
    
    </>
    );
  }