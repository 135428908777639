import "./Style/Style.css"
//import Teeth_Whitening from './Teeth_Whitening.pdf';
export default function Brouchers(){

   /* const TeethWhitening = require('./Teeth_Whitening.pdf');
    const Dental_Implants = require('./Dental_Implants.pdf');
    const Gum_Disease_And_Your_Health = require('./Gum_Disease_And_Your_Health.pdf');
    const Gum_Disease = require('./Gum_Disease.pdf');
    const MouthGuards = require('./MouthGuards.pdf');
    const Orthodontics = require('./Orthodontics.pdf');
    const Root_Canal = require('./Root_Canal.pdf');
*/
    return(
        <div className="Brouchers_Page">
        <h2 className="Brouchers_Page_title">Brochures</h2>

        <div className="Brouchers_Wrapper">
        <a className="Broucher" href="https://cdn.havelockdental.com/brochures/Teeth_Whitening.pdf" target="_blank">

<div className="Broucher_title">
Teeth Whitening
</div>

<div className="Broucher_Image">
    <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/5e74028a-54d9-4b3f-f05a-3f46f934a200/public" alt="" />
</div>

<div className="Broucher_reader">
    <p>2 Min Read</p>
</div>

</a>


<a className="Broucher" href="https://cdn.havelockdental.com/brochures/MouthGuards.pdf" target="_blank">

<div className="Broucher_title">
MouthGuards
</div>

<div className="Broucher_Image">
    <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/27ebcc29-8471-4afe-a97b-6e6eaa957100/public" alt="" />
</div>

<div className="Broucher_reader">
    <p>2 Min Read</p>
</div>

</a>



<a className="Broucher" href="https://cdn.havelockdental.com/brochures/Dental_Implants.pdf" target="_blank">

<div className="Broucher_title">
Dental Implants
</div>

<div className="Broucher_Image">
    <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/0ee0c510-724c-4544-190e-19db48a0eb00/public" alt="" />
</div>

<div className="Broucher_reader">
    <p>2 Min Read</p>
</div>

</a>



<a className="Broucher" href="https://cdn.havelockdental.com/brochures/Root_Canal.pdf" target="_blank">

<div className="Broucher_title">
Root Canal
</div>

<div className="Broucher_Image">
    <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/a0a843a8-faad-49a5-9055-8a28e1f33200/public" alt="" />
</div>

<div className="Broucher_reader">
    <p>2 Min Read</p>
</div>

</a>



<a className="Broucher" href="https://cdn.havelockdental.com/brochures/Gum_Disease_And_Your_Health.pdf" target="_blank">

<div className="Broucher_title">
Gum Disease & Your Health
</div>

<div className="Broucher_Image">
    <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ab702da8-30f5-4516-d39c-a92c96b9d900/public" alt="" />
</div>

<div className="Broucher_reader">
    <p>2 Min Read</p>
</div>

</a>


<a className="Broucher" href="https://cdn.havelockdental.com/brochures/Gum_Disease.pdf" target="_blank">

<div className="Broucher_title">
Gum Disease
</div>

<div className="Broucher_Image">
    <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/2fe8f825-99c7-453d-3506-e2aa9f812900/public" alt="" />
</div>

<div className="Broucher_reader">
    <p>2 Min Read</p>
</div>

</a>


<a className="Broucher" href="https://cdn.havelockdental.com/brochures/Orthodontics.pdf" target="_blank">

<div className="Broucher_title">
Orthodontics
</div>

<div className="Broucher_Image">
    <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/d2ae0a27-6029-405b-63a6-4c3725137400/public" alt="" />
</div>

<div className="Broucher_reader">
    <p>2 Min Read</p>
</div>

</a>

        </div>
        </div>
    )
}