import React from 'react';

const TreatmentQuicklinks = () => {
 
  return (
    <div className="TreatmentQuicklinks">
      <div className="title">
        <h1>OUR DENTAL CARE SERVICES INCLUDE</h1>
      </div>
      <a href="/Preventive Dentistry">
        <div className="treatment">
          <h1>Preventive Dentistry</h1>
          <img src={require('../HDC Icons/Group_2672.png')} alt=""/>
        </div>
      </a>
      <a href="/Cosmetic Aesthetic Dentistry"> 
        <div className="treatment">
          <h1>Cosmetic / Aesthetic Dentistry</h1>
          <img src={require('../HDC Icons/Group_2658.png')} alt=""/>
        </div>
      </a>
      <a href="/Dental Implants">
        <div className="treatment">
          <h1>Dental Implants</h1>
          <img src={require('../HDC Icons/Group_2659.png')} alt=""/>
        </div>
      </a>

      <a href="/Endodontics">
        <div className="treatment">
          <h1>Endodontics</h1>
            <img src={require('../HDC Icons/Group_2661.png')} alt=""/>
        </div>
      </a>
      <a href="/Orthodontics">
        <div className="treatment">
          <h1>Orthodontics</h1>
            <img src={require('../HDC Icons/Group_2673.png')} alt=""/>
        </div>
      </a>
      <a href="/Restorative-Dentistry">
        <div className="treatment">
          <h1>Restorative Dentistry</h1>
            <img src={require('../HDC Icons/Group_2675.png')} alt=""/>
        </div>
      </a>
      <a href="/Pedodontic">
        <div className="treatment">
          <h1>Pediatric Dentistry</h1>
            <img src={require('../HDC Icons/Group_2677.png')} alt=""/>
        </div>
      </a>
 
      <a href="/Prosthodontics">
        <div className="treatment">
          <h1>Prosthodontics</h1>
            <img src={require('../HDC Icons/Group_2679.png')} alt=""/>
        </div>
      </a>
      <a href="/Mobile Dental Services">
        <div className="treatment">
          <h1>Mobile Dental Services</h1>
            <img src={require('../HDC Icons/Group_2665.png')} alt=""/>
        </div>
      </a>
   
    </div>
  );
};

export default TreatmentQuicklinks;
