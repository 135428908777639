

import TreatmentQuicklinks from "../Components/TreatmentQuicklinks";
export default function Gallery() {
    return (
      
<>
<div className="container">
    <div className="gallery-container w-6 h-2">
        <div className="gallery-item">
          <div className="image">
            <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/03fed140-72dc-4f14-e63c-d20712c1f100/public" alt="Front Office"/>
          </div>
          <div className="text">Front Office </div>
        </div>
      </div>
      <div className="gallery-container w-4 h-3">
        <div className="gallery-item">
          <div className="image">
            <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/789ddfec-1360-49fd-6a12-06b21abc1b00/public" alt="Clinic 02"/>
          </div>
          <div className="text">Clinic 02</div>
        </div>
      </div>
      <div className="gallery-container w-2 h-3">
        <div className="gallery-item">
          <div className="image">
            <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/789ddfec-1360-49fd-6a12-06b21abc1b00/public" alt="Havelock Dental"/>
          </div>
          <div className="text">Havelock Dental</div>
        </div>
      </div>
  
      <div className="gallery-container w-2 h-2">
        <div className="gallery-item">
          <div className="image">
            <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/07526a26-87e4-42d4-3977-66afeb37a900/public" alt="Havelock Dental"/>
          </div>
          <div className="text">Havelock Dental</div>
        </div>
      </div>




  <div className="gallery-container w-4 h-2">
    <div className="gallery-item">
      <div className="image">
        <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/9a2dd084-8d4b-441e-ebf0-d9fd921c9c00/public" alt="Sterilization Station"/>
      </div>
      <div className="text">Sterilization Station</div>
    </div>
  </div>
  <div className="gallery-container w-3 h-2">
    <div className="gallery-item">
      <div className="image">
        <img src="https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/5dfda904-362d-467e-047e-8d49702a0c00/public" alt="Doctors Lounge"/>
      </div>
      <div className="text">Doctors Lounge</div>
    </div>
  </div>
  </div>

    
    
    </>
    );
  }