export default function Implant_Surgical_Guides() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/2f1045de-4484-4a86-6096-b7b4527b2b00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Digital Treatment Planning and Surgical Guides</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
        

        <div className="cardsplitcard">
        <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
        <h2>Efficiently Place Implants Right Where You Need Them
        </h2>

        </div>
        <div className="content_two"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
        <strong>  surgical guide helps with<br/>

        •  Place implants with maximum precision, safety and predictability<br/>

        • Our dental lab expertise ensures restorative-driven implant positioning and a functional, esthetic outcome<br/>

        • Avoid surprises and save chair time with a smooth, efficient surgical procedure<br/>

        • Attain the best imaginable result with as little discomfort for the patient as possible<br/>

        </strong>           

        </div>
          
        </div>


        <div className="cardsplitcard">
            <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
            <h2>Pricing
                 </h2>
            </div>
        <div className="content_two"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
        <strong> 
     
     • Planning 10 000 LKR<br/>

     • Printing 5 000 LKR<br/>


 </strong>

        </div>
          
        </div>












      <div className="card">
      <h2>Duration
          
          </h2>
          <strong>
            Within 24 hours 
          </strong>
          </div>
      <div className="card">
      <h2>Material Composition
      
      </h2>
      <p>
        Biocompatible photopolymer resin </p>
        </div>
      <div className="card">
      <h2>Indications
      
              </h2>
              <p>
                Surgical guides are intended for use as an aid in the surgical placement of endosseous dental implants.
              </p>
        </div>




        <div className="cardsplitcard">
            <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
            <h2>Questions about starting your first DTP case?
                 </h2>
                 <p> Contact us on :</p>
            </div>
        <div className="content_two"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
        <strong> 
     
     <a href="tel:+940112501094"> call us on +94 112 501 094</a><br/>
     <a href="mailto:clinic@havelockdental.com">Mail us on dtp@havelockdental.com</a><br/>

 </strong>

        </div>
          
        </div>


      </div>
    </div>
    
    
    </>
    );
  }