import React from "react";
import { Helmet } from "react-helmet";

//images 
import Picture1 from "./pics/Picture1.jpg";
import Picture2 from "./pics/Picture2.png";
import Picture3 from "./pics/Picture3.jpg";
import Picture4 from "./pics/Picture4.jpg";
import Picture5 from "./pics/Picture5.png";



const backgroundImageUrl = "https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public"; 

export function Crown_Blog() {


    return (
        <>
            <Helmet>
  <title>Crown Blog</title>
  <meta name="description" content="Learn about Crowns and how they can benefit you." />
  <meta name="keywords" content="Crowns, dental treatment, oral health" />
  <meta property="og:title" content="Crowns Blog" />
  <meta property="og:description" content="Learn about Crowns and how they can benefit you." />
  <link rel="canonical" href="/Crown-blog"/>
  <meta property="og:image" content={Picture1} />
  <meta property="og:url" content="https://havelockdental.com/Crown-blog" />
</Helmet>
        <div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/ced629c9-7445-4ca4-5968-b6c05f381a00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>What are dental crowns?</h1>

    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>


    <div className="rtc_Blog_01">
    
    <div className="Contentwrapper">
      <div className="cardwrapper">


        <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
          <h1>What are dental crowns?</h1>

<p>•	Over time, your teeth can get damaged. This can happen for a variety of reasons, like tooth decay, injuries or just use over time. Your teeth can lose their shape or size. Dental crowns are tooth-shaped “caps” that can be placed over your tooth. Think of it like a snug hat for your tooth. The crown restores the tooth’s shape, size, strength and appearance.</p>      
<br />

<p>•	The dental crown is cemented into place on your tooth, and it covers the visible portion of the tooth.</p>

          </div>
          <div className="content_two">
          <img style={{ maxWidth: '250px',width:'50%' }}  src={Picture2} alt="" />
          <img style={{ maxWidth: '250px',width:'50%' }}  src={Picture1} alt="" />

          </div>
        </div>
      </div>
    </div>
    


    <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
          <h2>How is my tooth prepared for a dental crown?</h2>

          <br />

          <ul>
  <li>You will typically have two visits to our clinic to prepare for a dental crown.</li>
  <li>We might have to do a root canal treatment before your dental crown procedure if there’s any:</li>
 
 <ul>

 <li>Tooth decay.</li>
 <li>Risk of infection.</li>
 <li>Injury to the tooth’s pulp.</li></ul>
 </ul>
          </div>
          <div className="content_two">
          <p>Pulp is the soft tissue inside your teeth that contains blood vessels, nerves and connective tissue.</p>


          </div>
        </div>
<br />
        <div className="card">
        <h2>What Type of Dental Crown Should you Choose?</h2>



                 
        </div>

        <br />


        <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
          <h1>PFM Crowns</h1>
          <img src={Picture3} style={{ maxWidth: '250px',width:'50%' }} alt="" />

          </div>
          <div className="content_two">
          <ul>
  <li>They provide great aesthetics and durability</li>
  <li>They’ve been around for over 50 years. We know they work well.</li>
  <li>They are less costly than all porcelain crowns</li>
</ul>
          </div>
        </div>
 
<br />

<div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
          <h1>Zirconia Crowns</h1>

          <img src={Picture4} style={{ maxWidth: '250px',width:'50%' }} alt="" />

          </div>
          <div className="content_two">
          <ul>
  <li>They provide great aesthetics</li>
  <li>They are strong and long-lasting (less possibilities of chipping or breaking).</li>
  <li>The process can be less time consuming because zirconia can be cut and shaped at the same dental office. There’s no need to send them over to a dental lab.</li>
  <li>Zirconia Crowns are less likely to wear down due to their strength.</li>
  <li>They are biocompatible: as metal free crowns, they are not likely to cause allergic reactions.</li>
</ul>
          </div>
        </div>
 
<br />


 

  



        <div className="cardsplitcard">
          <div className="contentone" style={{placeSelf: 'center'}}>
          <h1>E-MAX Crowns</h1>
          <img src={Picture5} alt="" />

          
          </div>
          <div className="content_two">
          <p>The newest type of crown in dentistry today is known as E -Max. It is a type of all-ceramic crown made of lithium disilicate (which is also light and thin).

</p>
 <h3>The main ADVANTAGES of E-max crowns are:</h3>
<ul>
  <li>Great aesthetics. They look great in your mouth.</li>
  <li>They can be durable and very strong.</li>
  <li>They provide a great choice both for front and back teeth.</li>
</ul>
          </div>
        </div>


        <br />


        <div className="card">
        <h2>A dental crown is a long-lasting restorative treatment. </h2>
        <p> That means you will have a crown for many years in your mouth. Certainly, you want to have it done in the most professional and skillful way.</p>
        <p>Maybe it’s better to spend a bit more and be sure the results will be good and long-lasting. Choosing only cheap options may lead to poor quality treatments which, in turn, will mean more money, more time and more discomfort in the future.</p>


                 
        </div>




 
 
  </div>

        </>
     
    );
}


export function Crown_Blog_summery() {
    return (
        <div className="Blog_Summery" style={{ 
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #66be9e8f 85%), url(${backgroundImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}>
              <h2>Dental Crowns </h2>
              <p>•	Over time, your teeth can get damaged. This can happen for a variety of reasons, like tooth decay, injuries or just use over time. Your teeth can lose their shape or size. Dental crowns are tooth-shaped “caps” that can be placed over your tooth. Think of it like a snug hat for your tooth. The crown restores the tooth’s shape, size, strength and appearance.</p>      
   <a href="/Crown-blog">Read More</a>

       </div>
    );
}