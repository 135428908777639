export default function Cosmetic_Aesthetic_Dentistry() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/c7e582d3-a7b6-46e2-a712-99a8027d3400/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Implants</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
        


      <div className="card">
            <h2>What are Dental Implants?
            </h2>
            <p>Dental implant surgery is a procedure that replaces tooth roots with metal, screwlike posts made of titanium which replaces damaged or missing teeth with artificial teeth that look and function much like real ones.
            </p>
           </div>

           <div id="Are dental implants painful?" className="card">
            <h2>Are dental implants painful?
            </h2>
            <p>Dental implants are considered to be the best options to replace missing or damaged teeth. The procedure itself is not painful since it is performed with either general or local anesthesia to completely numb the mouth. After dental implantation, once the numbness wears off, mild pain may be noticed by the patient.

            </p>
           </div>  
   

            <div className="card">
                <h2>Types of implants available?</h2>
                <p>Dental implants come in different sizes, heights and types. We can help you determine which option is best for you, depending on your particular needs.
                  There are two main types of implants:
                  Endosteal
                  These dental implants are placed in the jawbone. Typically made of titanium and shaped like small screws, they are the most commonly used type of implant.
                  Subperiosteal
                  These dental implants are placed under the gum but on, or above, the jawbone. This type of implant may be used in patients who do not have enough healthy natural jawbone and cannot, or do not want to, undergo a bone augmentation procedure to rebuild it.
                </p>
               </div>
               <div className="cardsplitcard">
                <div className="contentone" style={{ display: 'grid', justifyContent: 'center' }}>
                   <h2>How many implants can I place?</h2>
                   <p>Dependent on one’s individual situation, we at Havelock Dental Center will draw up a plan for implants that is most suited to you.

                   </p>
                      
                </div>
                   <div className="content_two">
                     <strong>  • Replacing a Single Tooth: This is quite simple, as you only need to replace that one tooth with one implant and crown.<br/>
                              • Replacing Several Teeth: Slightly more complicated, if you have to replace several teeth you will need to consider implant-supported bridges.<br/>
                              • Replacing All of Your Teeth: You may opt for an implant-supported full bridge or full denture in the event where you have to replace all your teeth.<br/>
                   </strong>
                       </div>
                   </div>
            

                   <div className="cardsplitcard">
                    <div className="contentone" style={{ display: 'grid', justifyContent: 'center' }}>
                       <h2>What are the Alternative Dental Implant Techniques?</h2>
                       <p>Depending on the health of your jawbone and your specific needs, your dental implant dentist may suggest some alternative treatment options in addition to the traditional multi-step dental implant procedure.
                        If your jawbone is not able to support dental implants, several techniques can be used to rebuild bone, restore your natural jawline and provide a sturdy foundation for implant-supported teeth. These include:
                      </p>
                          
                    </div>
                       <div className="content_two">
                         <strong> 
                          • Bone augmentation - This involves restoring or regenerating bone in your jaw when it is not able to support implants otherwise. Research shows that using bone additives and growth factors to fortify the bone generally achieves the best results.<br/>
                          • Sinus lift - Also called sinus augmentation or sinus elevation, this involves adding bone below the sinus in cases where natural bone has deteriorated due to missing upper back teeth.<br/>
                          • Ridge expansion - If your jaw isn’t wide enough to support dental implants, bone graft material can be added to a small ridge, or space, created along the top of your jaw.<br/>
                       </strong>
                           </div>
                       </div>

               <div className="card">
                <h2>How does 3D Imaging and Treatment Planning help to place implants?
                </h2>
                <p>State-of-the-art, highly precise 3D digital imaging and implant surgical planning software have made implant procedures faster and highly predictable. We, Havelock dental Center use these tools to analyze the anatomy of your jaw and determine the best sites for implant placement before surgery. This saves time and money, and shortens recovery time.
                  
                </p>
               </div>
               <div className="card">
                <h2>What is Immediate Load Dental Implants?</h2>
                <p>Also called same day implants, immediate load dental implants allow placement of a temporary tooth during the same appointment as your dental implant placement. This may be a good option if you have enough natural bone and an implant secure enough to support immediate placement and pressure on the new temporary tooth.

                </p>
               </div>
               <div className="card">
                <h2>What are Mini Dental Implants (MDIs)?</h2>
                <p>Also called small or narrow diameter implants, these toothpick-sized implants are narrower than most commonly used dental implants. They are placed through less-invasive techniques and are used primarily to stabilize a lower denture.
                </p>
               </div>
               <div className="card">
                <h2>What type of restoration is recommended for edentulous patients? All-on-4</h2>
                <p>All-on-4 is an alternative to placing a top or bottom set of replacement teeth, called a full arch. Four dental implants are placed in available bone, avoiding the need for bone grafting. Special abutments are used so that a temporary set of replacement teeth can be placed the same day. A modified diet should be followed while the gum tissues heal and the implants bond with your natural bone. After about six months, the permanent replacement teeth will be placed and you can resume a regular diet.
                </p>
               </div>
               <div className="card">
                <h2>How do I care for my dental implants? </h2>
                <p>Rigorous dental hygiene practices consisting of both at-home care as well as visits to your dental clinic are mandatory for optimal oral health. Both your natural teeth as well as implants need to be treated in the same way. Of course regular brushing and flossing are great ways to keep your implants clean and plaque-free.
                  Post-treatment, the best care plan will be drawn up for you by us to follow with your help. Remember you follow-up with visits that will help you observe your implant, teeth and gums to ensure they are healthy.
                </p>
               </div>




      </div>
    </div>
    
    
    </>
    );
  }