export default function Periodontics() {
    return (
      
<>

<div className="header" style={ 
   {
    backgroundImage: `url('https://imagedelivery.net/hX9G_c4QADXIzWiXt4wnPQ/3a64ecf5-3868-4282-4d76-3f6ef18c3d00/public')`
//    backgroundImage: `url(${item.image_url})`
   }
    
    }>
    
    <div className="inner-header flex">
        

    <h1>Implants</h1>
    </div>
    
    <div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
    </div>
    
    </div>

    <div className="Contentwrapper">
      <div className="cardwrapper">
        

      <div className="card">
            <h2>What Is Periodontics / Gum and Bone Disease?</h2>
            <p>Periodontal disease is most often caused by poor oral hygiene, dental plaque, smoking, genetic factors, stress, diet, age and illness. It is an ongoing bacterial infection in the gums and bone around your teeth that can lead to an inflammation under the gums. If left untreated, the inflammation can destroy the bone around your teeth resulting in tooth loss.
              <br/>
              Any changes in the way your teeth fit together when you bite, any change in the fitting of partial dentures, loose, separating or protruding teeth, and spaces between teeth, are also symptoms of periodontal disease.
            </p>
           </div>



           <div className="cardsplitcard">
            <div className="contentone"  style={{ padding: '15px', justifyContent: 'center',textAlign:'left' }} >
               <h2>What are the treatments that come under periodontics?
              </h2> 
              <strong>
                • Deep scaling and root planning<br/>
                • Guided tissue regeneration<br/>
                • Ridge augmentation<br/>
                • Aesthetic crown lengthening and gingival contouring<br/>
                • Root coverage
              </strong>        </div>
             
               </div>
    
        
               <div className="card">
                <h2>What Is Deep Scaling And Root Planning?</h2>
                <p>Scaling and root planing are one of the most common and effective means of treating periodontal gum diseases wherein the dentist cleans between the gums and teeth, down to the roots.
                Scaling involves the removal of accumulated calculus that is attached to the tooth surfaces, as well as cleaning the areas below the gum lines along the roots, which are hard-to-reach areas when brushing and flossing. The dentist utilizes ultrasonic instruments such as tiny vibrant which breaks the tartar, removing stains and plaque.
                Root planing is the smoothing of the root surfaces to avoid bacterial growth after scaling.
                   </p>
               </div>

       
               <div className="card">
                <h2>What Is Guided Tissue Regeneration?</h2>
                <p>Periodontal gum diseases can start as a result of insufficient brushing and flossing. The plaque build-up and tartar can eventually affect the surrounding bone and teeth tissues. When the fiber that binds the tooth and bone together is destroyed, it opens up spaces between the gum and the tooth, creating pockets where plaque can get trapped and normal brushing cannot get to.
                  In severe cases of periodontal gum disease, surgery will be required once the gum tissue shrinks or becomes damaged. Guided tissue regeneration (GTR) is a dental surgical procedure which enables bone and tissue re-growth in the impacted area. This surgical process allows the re-growth of tissue and bone around the affected tooth or, in the case of tooth loss, the surgical procedure increases the bone capacity for dental implantation.
                  The success of the guided tissue regeneration is heavily dependent on the maintenance of good oral practices, oral hygiene and proper nutrition of the patients.
                 </p>
               </div>
     
               <div className="card">
                <h2>What Is Ridge Augmentation?</h2>
                <p>Ridge augmentation is done when a sunken spot or concave appears in your gum line after an extraction. Apart from not being aesthetically pleasing, it can also jeopardize the appearance of a possible implant, bridge or any other prosthetic procedure that goes over the area. Another option is to insert grafting material under the gum to fill this depression and build up an area of bone so that a dental implant can be placed.
                     </p>
               </div>


               <div className="card">
                <h2>What Is Aesthetic Crown Lengthening and Gingival Contouring?</h2>
                <p>In cases where the teeth are quite small compared to the gum tissue, aesthetic crown lengthening and gingival contouring can be done to avoid having a “gummy” smile. An uneven gum line, on the other hand, can be corrected by lowering the gum line and the bone just beneath it to change the appearance of your smile and make your teeth appear longer.
                  “Gingival (gum) contouring” is the process of re-contouring the gums only.
                  “Crown lengthening” refers to re-contouring both the gums and supporting bone.</p>
               </div>

               <div className="card">
                <h2>What Is Root Coverage?</h2>
                <p>Root coverage is done to protect the exposed tooth roots due to receding gum lines. When the gum tissue that protects the delicate roots of your teeth are lost, it will be prone to high sensitivity, tooth decay, root canals, discoloration and actual jawbone loss, which can all lead to tooth loss. Also, longer looking teeth and exposed tooth roots give an “old” appearance to your smile.
                  The procedure called Soft Tissue Grafting replaces the missing gum tissue and protects your teeth. For this reason, soft tissue grafting can add a new youthful appearance to your smile and may be recommended even for back teeth. There are several techniques for this kind of procedure and the graft material may be obtained in different ways. Consult your periodontist to find out which kind of therapy fits your dental needs.
                  </p>
               </div>




      </div>
    </div>
    
    
    </>
    );
  }